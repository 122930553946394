import { Params } from '@angular/router';

import {
  isEmpty as _isEmpty, isObject as _isObject, isNumber as _isNumber, isString as _isString, isUndefined as _isUndefined
} from 'lodash';

import { Direction } from './sort/direction/direction.model';
import { Sort } from './sort/sort.model';


export class SearchCriteria {
  protected pageNumber: number;
  protected pageSize: number;
  protected query: string;
  protected sort: Sort;

  constructor() {
    this.reset();
  }

  /**
   * Returns the itself formatted as endpoint expects.
   *
   * @returns any
   */
  asEndpointData(): any {
    const params: any = {
      q: this.getQuery(),
      page: this.getPageNumber(),
      size: this.getPageSize()
    };

    if (this.hasSort()) {
      params.sort = this.getSort().toString();
    }

    return params;
  }

  /**
   * Returns the itself formatted as URL expects.
   *
   * @returns any
   */
  asUrlOutput(): any {
    const params: any = {
      q: this.getQuery(),
      page: this.getPageNumber(),
      size: this.getPageSize(),
    };

    if (this.hasSort()) {
      params.sort = this.getSort().toString();
    }

    return params;
  }


  /**
   * Returns the page number.
   *
   * @returns number
   */
  getPageNumber(): number {
    return this.pageNumber;
  }

  /**
   * Returns the page size.
   *
   * @returns number
   */
  getPageSize(): number {
    return this.pageSize;
  }

  /**
   * Returns the query.
   *
   * @returns string
   */
  getQuery(): string {
    return this.query;
  }

  /**
   * Returns the sort.
   *
   * @returns Sort
   */
  getSort(): Sort {
    return this.sort;
  }

  /**
   * Indicates if it has a sort.
   *
   * @returns boolean
   */
  hasSort(): boolean {
    const hasIt: boolean = _isObject(this.getSort());
    return hasIt;
  }

  /**
   * Indicates if the query is empty.
   *
   * @returns boolean
   */
  isQueryEmpty(): boolean {
    const isIt: boolean = (this.getQuery() === '');
    return isIt;
  }

  /**
   * Increases the page number by one.
   *
   * @returns void
   */
  nextPage(): void {
    this.setPageNumber(this.getPageNumber() + 1);
  }


  /**
   * Decrements the page number by one.
   *
   * @returns void
   */
  previousPage(): void {
    this.setPageNumber(this.getPageNumber() - 1);
  }

  /**
   * Reads the given URL parameters and stores the options.
   *
   * @param params Params
   * @throws Error if the given parameters are invalid.
   * @returns void
   */
  readUrlParams(params: Params): void {
    if (_isObject(<Params>params) && !_isEmpty(params)) {
      this.setPageNumber(Number(params.page));
      this.setPageSize(Number(params.size));
      this.setQuery(params.q);

      if (_isObject(<Sort>params.sort)) {
        const sortKeys: string[] = params.sort.trim().split(':');

        if (sortKeys.length > 0) {
          const sort: Sort = new Sort(sortKeys[0], new Direction(<any>sortKeys[1]));
          this.setSort(sort);
        }
      }
    } else {
      throw new Error('Invalid URL parameters given to read.');
    }
  }

  /**
   * Reset the stored data.
   *
   * @returns void
   */
  reset(): void {
    this.setPageNumber(0);
    this.setPageSize(1);
    this.setQuery('');
    this.setSort(undefined);
  }

  /**
   * Set the page number.
   *
   * @param pageNumber number
   * @throws Error if the given page number is invalid.
   * @returns void
   */
  setPageNumber(pageNumber: number): void {
    if (_isNumber(pageNumber) && (pageNumber >= 0)) {
      this.pageNumber = pageNumber;
    } else {
      throw new Error('Invalid page number given.');
    }
  }

  /**
   * Set the page size.
   *
   * @param pageSize number
   * @throws Error if the given page size is invalid.
   * @returns void
   */
  setPageSize(pageSize: number): void {
    if (_isNumber(pageSize) && (pageSize >= 1)) {
      this.pageSize = pageSize;
    } else {
      throw new Error('Invalid page size given.');
    }
  }

  /**
   * Set the query.
   *
   * @param query string
   * @throws Error if the given query is invalid.
   * @returns void
   */
  setQuery(query: string): void {
    if (_isString(query)) {
      this.query = query;
    } else {
      throw new Error('Invalid query given.');
    }
  }

  /**
   * Set the sort.
   *
   * @param sort Sort
   * @throws Error if the given sort-by is invalid.
   * @returns void
   */
  setSort(sort: Sort): void {
    if (_isUndefined(sort)) {
      this.sort = undefined;
    } else if (sort instanceof Sort) {
      this.sort = sort;
    } else {
      throw new Error('Invalid sort given.');
    }
  }

  /**
   * Reads and returns the value of the given parameter name as boolean.
   * If the parameter value can't be interpreted as boolean returns undefined.
   *
   * @param params Params
   * @param paramName string
   * @returns boolean | undefined
   */
  protected readParamAsBoolean(params: Params, paramName: string): boolean | undefined {
    let paramValue: boolean | undefined;

    switch (params[paramName].trim().toLowerCase()) {
      case 'true':
        paramValue = true;
        break;
      case 'false':
        paramValue = false;
        break;
      default:
        paramValue = undefined;
        break;
    }

    return paramValue;
  }
}
