import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CalendarModule, DropdownModule, MultiSelectModule } from 'primeng';
import { CommonModule } from '@angular/common';
import { CommonModule as BoltCommonModule } from '@bolt/ui-shared/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { DroplistsModule } from '@bolt/ui-shared/droplists';
import { BoltCalendarFieldComponent } from './components/bolt-calendar-field/bolt-calendar-field.component';
import { BoltDropdownFieldComponent } from './components/bolt-dropdown-field/bolt-dropdown-field.component';
import { BoltFormFieldErrorMessagesComponent } from './components/bolt-form-field-error-messages/bolt-form-field-error-messages.component';
import { BoltGroupedMultiselectAccessibilityDirective } from './directives/bolt-group-multiselect-accessibility/bolt-group-multiselect-accessibility.directive';
import { BoltGroupedMultiselectEllipsisDirective } from './directives/bolt-group-multiselect-ellipsis/bolt-group-multiselect-ellipsis.directive';
import { BoltGroupedMultiselectFieldComponent } from './components/bolt-grouped-multiselect-field/bolt-grouped-multiselect-field.component';
import { BoltMultiselectFieldComponent } from './components/bolt-multiselect-field/bolt-multiselect-field.component';
import { BoltProximityFieldComponent } from './components/bolt-proximity-field/bolt-proximity-field.component';
import { BoltReadFieldComponent } from './components/bolt-read-field/bolt-read-field.component';
import { BoltNumberFieldComponent } from './components/bolt-number-field/bolt-number-field.component';
import { BoltTextFieldComponent } from './components/bolt-text-field/bolt-text-field.component';
import { RouterModule } from '@angular/router';
import { ConfigurationModule } from '@bolt/ui-shared/configuration';
import { HttpClientModule } from '@angular/common/http';
import { BoltMultiFieldAutocompleteComponent } from './components/bolt-multi-field-autocomplete/bolt-multi-field-autocomplete.component';
import { DataTransformModule } from '@bolt/ui-shared/data-transform';
import { BoltListAccordionFieldComponent } from './components/bolt-list-accordion-field/bolt-list-accordion-field.component';


@NgModule({
  imports: [
    AngularMultiSelectModule,
    CommonModule,
    ConfigurationModule,
    HttpClientModule,
    FormsModule,
    BoltCommonModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    DroplistsModule,
    ReactiveFormsModule,
    RouterModule,
    DataTransformModule
  ],
  declarations: [
    BoltCalendarFieldComponent,
    BoltDropdownFieldComponent,
    BoltFormFieldErrorMessagesComponent,
    BoltGroupedMultiselectAccessibilityDirective,
    BoltGroupedMultiselectEllipsisDirective,
    BoltGroupedMultiselectFieldComponent,
    BoltListAccordionFieldComponent,
    BoltMultiFieldAutocompleteComponent,
    BoltMultiselectFieldComponent,
    BoltProximityFieldComponent,
    BoltReadFieldComponent,
    BoltNumberFieldComponent,
    BoltTextFieldComponent
  ],
  exports: [
    BoltCalendarFieldComponent,
    BoltDropdownFieldComponent,
    BoltFormFieldErrorMessagesComponent,
    BoltGroupedMultiselectFieldComponent,
    BoltListAccordionFieldComponent,
    BoltMultiFieldAutocompleteComponent,
    BoltMultiselectFieldComponent,
    BoltProximityFieldComponent,
    BoltReadFieldComponent,
    BoltNumberFieldComponent,
    BoltTextFieldComponent
  ],
  providers: []
})
export class FormModule { }
