import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-multiselect-field',
  templateUrl: 'bolt-multiselect-field.html',
  styleUrls: ['bolt-multiselect-field.scss']
})
export class BoltMultiselectFieldComponent extends BoltInputField {
  @Input() loading: boolean = false;
  @Input() options: SelectionItem[];
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize: number;

  value: number[];

  // We need this hardcoded number to limit the items to show.
  readonly maxSelectedLabels: number = 999999;

  constructor(
    public ngControl: NgControl,
    public appConfigurationManager: AppConfigurationManager
  ) {
    super(ngControl);
  }
}
