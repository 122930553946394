import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule, MessageService } from 'primeng';

import { BoltNotificationComponent } from './components/bolt-notification/bolt-notification.component';
import { NotificationService } from './services/notification.service';
import { BoltOutageNotificationComponent } from './components/bolt-outage-notification/bolt-outage-notification.component';


@NgModule({
  imports: [
    CommonModule,
    ToastModule
  ],
  declarations: [
    BoltNotificationComponent,
    BoltOutageNotificationComponent
  ],
  exports: [
    BoltNotificationComponent,
    BoltOutageNotificationComponent
  ],
  providers: [
    MessageService,
    NotificationService
  ]
})
export class NotificationModule { }
