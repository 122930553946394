export const appConfigGlobalMock = {
  auth: {
    enabled: 'auth.enabled',
    oauth: {
      token: 'auth.oAuth2.tokenName'
    }
  },
  serviceConfig: {
    global: {
      request: {
        retries: 'serviceConfig.global.request.retries'
      }
    }
  },
  ux: {
    calendar: {
      yearRange: 'ux.calendar.yearRange',
    },
    growl: {
      life: 'ux.growl.life',
    },
    multiSelect: {
      scrollHeight: 'ux.multiSelect.scrollHeight',
      maxValuesAsString: 'ux.multiSelect.maxValuesAsString',
      maxSelectedLabels: 'ux.multiSelect.maxSelectedLabels'
    },
    page: {
      tasksList: {
        pageSize: 'ux.page.tasksList.pageSize'
      },
      talent: {
        searchPageSize: 'ux.page.talent.searchPageSize',
      },
      titlesList: {
        pageSize: 'ux.page.titlesList.pageSize'
      }
    }
  },
  application: {
    taskListFilters: 'application.taskListFilters'
  }
};
