import { Directive, ElementRef, Renderer2, OnChanges, Input, SimpleChanges } from '@angular/core';


@Directive({
  selector: '[boltNg2Rtl]',
})
export class BoltNg2RtlDirective implements OnChanges {
  @Input('boltNg2Rtl')
  rtl: boolean = true;

  constructor(protected renderer: Renderer2, protected elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rtl.currentValue !== changes.rtl.previousValue) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'direction',
        this.rtl ? 'rtl' : 'ltr'
      );
    }
  }
}
