import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { isArray as _isArray } from 'lodash';

import { SeverityEnum } from '../../enums/severity.enum';


@Component({
  selector: 'bolt-tip-panel',
  templateUrl: 'bolt-tip-panel.html',
  styleUrls: ['bolt-tip-panel.scss'],
})
export class BoltTipPanelComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() separator: string = ';';
  @Input() text: string | string[] = '';
  @Input() title: string  = '';
  @Input() type: SeverityEnum = SeverityEnum.INFO;

  tips: string[];
  styleClass: string;

  constructor() { }

  ngOnInit() {
    this.setTips();
    this.setClass();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.setTips();
      this.setClass();
    }
  }

  /**
   * Sets the tips value array depending on the current text value
   *
   * @returns void
   */
  setTips(): void {
    this.tips = _isArray(this.text) ? this.text : this.text.split(this.separator).map(
      (item: string) => item.trim()
    );
  }

  /**
   * Sets the style class for the tip icon depending on the current severity type
   *
   * @returns void
   */
  setClass(): void {
    switch (this.type) {
      case SeverityEnum.ERROR:
        this.styleClass = 'fa fa-times-circle';
        break;
      case SeverityEnum.SUCCESS:
        this.styleClass = 'fa fa-check-circle';
        break;
      case SeverityEnum.WARN:
        this.styleClass = 'fa fa-exclamation-circle';
        break;
      default:
        this.styleClass = 'fa fa-info-circle';
        break;
    }
  }
}
