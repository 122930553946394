import { AccountInterface } from '../account/account.interface';
import { TerritoryAccountUnitInterface } from '../territory-account-unit/territory-account-unit.interface';


export enum GenreType {
  ACCOUNT_TERRITORY = <any>'ACCOUNT_TERRITORY',
  ACCOUNT = <any>'ACCOUNT',
  NONE = <any>'NONE',
}

export enum GenreTitleType {
  FEATURE = <any>'FEATURE',
  SEASON = <any>'SEASON',
  ANY = <any>'ANY'
}

export interface GenreInterface {
  id: number;
  name: string;
  titleType: GenreTitleType;
  genreType: GenreType;
  definition: string;
  territoryAccountUnits?: TerritoryAccountUnitInterface[];
  accounts?: AccountInterface[];
}

export class Genre implements GenreInterface {
  public accounts: AccountInterface[] = [];
  public territoryAccountUnits: TerritoryAccountUnitInterface[] = [];
  public definition: string = '';

  constructor(
    public id: number,
    public name: string,
    public titleType: GenreTitleType,
    public genreType: GenreType,
    public territoryAccountUnitsValue: TerritoryAccountUnitInterface[] = [],
    public accountsValue: AccountInterface[] = [],
  ) {
    this.accounts = accountsValue;
    this.territoryAccountUnits = territoryAccountUnitsValue;
  }

  setApplicableAccounts(accounts: AccountInterface[]) {
    this.accounts = accounts;
    return this;
  }

  setApplicableTerritoryAccountUnits(territoryAccountUnits: TerritoryAccountUnitInterface[]) {
    this.territoryAccountUnits = territoryAccountUnits;
    return this;
  }

  toString() {
    return this.name;
  }
}
