import { isUndefined as _isUndefined } from 'lodash';
import * as moment from 'moment';


export abstract class DateHelper {
  /**
   * Generates and returns a year range using the given pattern.
   *
   * @param pattern string
   * @returns number[]
   */
  static generateYearRange(pattern: string): number[] {
    const sections: string[] = pattern.toLowerCase().split(':');

    const range: number[] = [
      this.calculateYear(sections[0]),
      this.calculateYear(sections[1])
    ];

    return range;
  }

  /**
   * Calculates and returns the year from the given value.
   *
   * @param value string
   * @returns number
   */
  protected static calculateYear(value: string): number {
    let year: number = moment().year();

    if (!_isUndefined(value) && (value !== 'c')) {
      year += parseInt(value.replace(/c?/i, ''), 10);
    }

    return year;
  }
}
