import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-notification',
  templateUrl: 'bolt-notification.html',
  styleUrls: ['bolt-notification.scss']
})
export class BoltNotificationComponent {
  @Input() container: string;

  constructor() { }
}
