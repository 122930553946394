import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-spinner-mark',
  templateUrl: 'bolt-spinner-mark.html',
  styleUrls: ['bolt-spinner-mark.scss']
})
export class BoltSpinnerMarkComponent {
  @Input() hideLegend: boolean;
  @Input() legend: string;
  @Input() show: boolean;
  @Input() size: number;

  constructor() {
    this.hideLegend = false;
    this.legend = 'Processing...';
    this.show = false;
    this.size = 1;
  }
}
