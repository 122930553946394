import { Injectable } from '@angular/core';
import { isNumber as _isNumber, isObject as _isObject } from 'lodash';

import { Attribute } from '../../models/functional-metadata/attribute/attribute.model';
import { FunctionalMetadataEnum } from '../../models/functional-metadata/functional-metadata.enum';
import { LevelModeEnum } from '../../models/functional-metadata/level-mode/level-mode.enum';


@Injectable({
  providedIn: 'root',
})
export class FunctionalMetadataService {

  private attributesMap = new Map();

  constructor() {
    this.attributesMap.set(
      LevelModeEnum.account,
      [
        new Attribute(FunctionalMetadataEnum.excludeKidsMode, true, true),
        new Attribute(FunctionalMetadataEnum.pseDisclaimer, true, false),
        new Attribute(FunctionalMetadataEnum.productPlacementDisclaimer, true, false),
        new Attribute(FunctionalMetadataEnum.smokingDisclaimer, true, true),
        new Attribute(FunctionalMetadataEnum.includeKidsMode, true, false),
        new Attribute(FunctionalMetadataEnum.graphicContentDisclaimer, true, false),
        new Attribute(FunctionalMetadataEnum.displayDubTalent, true, false),
        new Attribute(FunctionalMetadataEnum.heritageDisclaimer, false, false)
      ]
    );

    this.attributesMap.set(
      LevelModeEnum.territory,
      [
        new Attribute(FunctionalMetadataEnum.heritageDisclaimer, false, false),
        new Attribute(FunctionalMetadataEnum.smokingDisclaimer, false, false),
        new Attribute(FunctionalMetadataEnum.excludeKidsMode, false, false)
      ]
    );

    this.attributesMap.set(
      LevelModeEnum.territoryAccount,
      [
        new Attribute(FunctionalMetadataEnum.excludeKidsMode, true, true),
        new Attribute(FunctionalMetadataEnum.pseDisclaimer, true, true),
        new Attribute(FunctionalMetadataEnum.productPlacementDisclaimer, true, true),
        new Attribute(FunctionalMetadataEnum.smokingDisclaimer, true, true),
        new Attribute(FunctionalMetadataEnum.includeKidsMode, true, true),
        new Attribute(FunctionalMetadataEnum.graphicContentDisclaimer, true, true),
        new Attribute(FunctionalMetadataEnum.displayDubTalent, true, true),
        new Attribute(FunctionalMetadataEnum.heritageDisclaimer, false, false)
      ]
    );

    this.attributesMap.set(
      LevelModeEnum.title,
      [
        new Attribute(FunctionalMetadataEnum.heritageDisclaimer, true, false),
        new Attribute(FunctionalMetadataEnum.smokingDisclaimer, true, false),
        new Attribute(FunctionalMetadataEnum.excludeKidsMode, false, false)
      ]
    );
  }

  /**
   * Returns the functional metadata attributes related to a given level.
   *
   * @param functionalMetadataLevel LevelModeEnum
   * @returns FunctionalMetadataAttribute[]
   */
  getAttributesByLevel(functionalMetadataLevel: LevelModeEnum): Attribute[] {
    const attributes = this.attributesMap.get(functionalMetadataLevel);

    return attributes;
  }
}
