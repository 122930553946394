import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppConfigProvider } from './providers/app-config.provider';
import { AppConfigurationManager } from './services/app-configuration/app-configuration.manager';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [],
  providers: [
    AppConfigurationManager,
    AppConfigProvider
  ]
})
export class ConfigurationModule { }
