import { Component, Input } from '@angular/core';
import { capitalize as _capitalize, isString as _isString } from 'lodash';


@Component({
  selector: 'bolt-type-label',
  templateUrl: 'bolt-type-label.html',
  styleUrls: ['bolt-type-label.scss']
})
export class BoltTypeLabelComponent {
  @Input() shortMode: boolean;

  protected _type: string;
  protected validFlag: boolean;

  constructor() {
    this.initialize();
  }

  @Input()
  set type(type: string) {
    this.validFlag = _isString(type);
    this._type = (this.isValid() ? _capitalize(type.trim()) : 'INVALID');
  }

  /**
   * Returns the type class.
   *
   * @returns string
   */
  getTypeClass(): string {
    return this._type.toLowerCase();
  }

  /**
   * Returns the type name.
   *
   * @returns string
   */
  getTypeName(): string {
    if (this.shortMode) {
      return this._type[0];
    } else {
      return this._type;
    }
  }

  /**
   * Returns the type title.
   *
   * @returns string
   */
  getTypeTitle(): string {
    if (this.shortMode) {
      return this._type;
    } else {
      return '';
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.type = undefined;
    this.shortMode = false;
  }

  /**
   * Indicates if it is invalid.
   *
   * @returns boolean
   */
  protected isInvalid(): boolean {
    return !this.isValid();
  }

  /**
   * Indicates if it is valid.
   *
   * @returns boolean
   */
  protected isValid(): boolean {
    return this.validFlag;
  }
}
