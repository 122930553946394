import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NodePropsEnum } from '../../enums/node-props/node-props.enum';


@Directive({ selector: 'angular2-multiselect[boltAccessibility]' })
export class BoltGroupedMultiselectAccessibilityDirective implements OnInit, OnDestroy {
  private eventListenerRemoval: CallableFunction;

  private readonly arrowUpCode: number = 38;
  private readonly groupSelector: string = 'grp-title';
  private readonly itemSelector: string = 'grp-item';
  private readonly selectAllSelector: string = 'select-all';

  constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) { }

  ngOnInit() {
    const target: HTMLInputElement = this.elementRef.nativeElement.querySelector('.selected-list .c-btn');

    this.eventListenerRemoval = this.renderer.listen(
      target,
      'keydown.space',
      (event: KeyboardEvent) => {
        event.stopPropagation();
        target.click();
      }
    );
  }

  ngOnDestroy() {
    if (this.eventListenerRemoval) {
      this.eventListenerRemoval();
    }
  }

  @HostListener('keydown.enter', ['$event'])
  handleKeyCheck(event: any) {
    event.stopPropagation();

    const includedSelectors: string[] = [this.selectAllSelector, this.groupSelector, this.itemSelector];
    const canClick: boolean = this.hasSelector(event, includedSelectors);

    if (canClick) {
      event.target.click();
    }
  }

  @HostListener('keydown.arrowup', ['$event'])
  @HostListener('keydown.arrowdown', ['$event'])
  handleKeyNavigation(event: any) {
    event.stopPropagation();

    const excludedSelectors: string[] = [this.selectAllSelector, 'list-filter'];
    const canNavigate: boolean = !this.hasSelector(event, excludedSelectors);

    if (canNavigate) {
      const sourceSpan: Element = event.target.parentNode;
      const targetElement: any = event.which === this.arrowUpCode
        ? this.findPreviousElement(sourceSpan)
        : this.findNextElement(sourceSpan);

      targetElement.focus();
    }
  }

  /**
   * Adds click event to the current query selector.
   *
   * @param event any
   * @param querySelector string
   * @returns void
   */
  protected addClickEvent(event: any, querySelector: string): void {
    const targets: HTMLInputElement[] = this.elementRef.nativeElement.querySelectorAll(querySelector);

    targets.forEach(
      (target: HTMLInputElement) => {
        if (event.target.contains(target.firstElementChild)) {
          target.click();
        }
      }
    );
  }

  /**
   * Finds the element based on a current property and selector.
   *
   * @param elem Element
   * @param currentProp string
   * @param selector string
   * @returns Element
   */
  protected findElement(elem: Element, currentProp: string, selector: string): Element {
    let notFound: boolean = true;
    let targetElement: Element;

    while (notFound) {
      elem = elem[currentProp];

      if (elem.classList.contains(selector)) {
        targetElement = elem.firstElementChild;
        notFound = false;
      } else {
        targetElement = elem;
      }
    }

    return targetElement;
  }

  /**
   * Finds the next element of a source input.
   *
   * @param sourceSpan Element
   * @returns Element
   */
  protected findNextElement(sourceSpan: Element): Element {
    const querySelector: string = '.cuppa-dropdown .dropdown-list .list-area .lazyContainer .grp-title';
    const group: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll(querySelector);
    const siblingSpan = sourceSpan.nextElementSibling;
    let targetInput: Element;

    if (this.isLastElement(sourceSpan, group)) {
      return sourceSpan;
    }

    if (sourceSpan.classList.contains(this.groupSelector)) {
      targetInput = this.findElement(sourceSpan.lastElementChild, NodePropsEnum.firstElementChild, this.itemSelector);

    } else if (siblingSpan) {
      targetInput = this.findElement(siblingSpan, NodePropsEnum.firstElementChild, this.itemSelector);

    } else {
      const nextSiblingParentNode: Element = this.findNextSiblingParentNode(sourceSpan, [this.itemSelector, this.groupSelector]);

      targetInput = nextSiblingParentNode ? nextSiblingParentNode.firstElementChild : sourceSpan;
    }

    return targetInput;
  }

  /**
   * Gets the previous element of a source input.
   *
   * @param sourceSpan Element
   * @returns Element
   */
  protected findPreviousElement(sourceSpan: Element): Element {
    const siblingSpan: Element = (sourceSpan.parentNode as Element).previousElementSibling;
    let targetInput: Element;

    if (sourceSpan.classList.contains(this.groupSelector)) {
      targetInput = siblingSpan
        ? this.findElement(siblingSpan.firstElementChild, NodePropsEnum.lastElementChild, this.itemSelector)
        : sourceSpan;
    } else {
      targetInput = siblingSpan
        ? this.findElement(siblingSpan, NodePropsEnum.firstElementChild, this.itemSelector)
        : this.findElement(sourceSpan, NodePropsEnum.parentNode, this.groupSelector);
    }

    return targetInput;
  }

  /**
   * Finds the next sibling element of a source input based on selectors.
   *
   * @param elem Element
   * @param selectors string[]
   * @returns Element
   */
  protected findNextSiblingParentNode(elem: Element, selectors: string[]): Element {
    let notFound: boolean = true;
    let parentNode: Element;

    while (notFound) {
      elem = elem.parentNode as Element;
      const nextElementSibling: Element = elem.nextElementSibling;

      if (nextElementSibling) {
        const hasExpectedSelectors: boolean = selectors.some(
          (selector: string) => nextElementSibling.firstElementChild.classList.contains(selector)
        );

        if (hasExpectedSelectors) {
          parentNode = nextElementSibling.firstElementChild;
          notFound = false;
        }
      }
    }

    return parentNode;
  }

  /**
   * Indicates if the event has some selector.
   *
   * @param event any
   * @param selectors string[]
   * @returns boolean
   */
  protected hasSelector(event: any, selectors: string[]): boolean {
    return selectors.some((selector: string) => event.target.parentNode.classList.contains(selector));
  }

  /**
   * Indicates if it's the last element of a last group.
   *
   * @param element Element
   * @param group NodeListOf<HTMLElement>
   * @returns boolean
   */
  protected isLastElement(elem: Element, group: NodeListOf<HTMLElement>): boolean {
    const lastElement = group[group.length - 1].lastElementChild;

    return lastElement.lastElementChild.isEqualNode(elem.parentNode);
  }
}
