import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';
import { isString as _isString } from 'lodash';

import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { DateHelper } from '@bolt/ui-shared/data-transform';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-calendar-field',
  templateUrl: 'bolt-calendar-field.html',
  styleUrls: ['bolt-calendar-field.scss']
})
export class BoltCalendarFieldComponent extends BoltInputField {
  value: string;
  yearRange: string;

  constructor(
    public ngControl: NgControl,
    protected appConfigurationManager: AppConfigurationManager
  ) {
    super(ngControl);

    this.yearRange = DateHelper.generateYearRange(this.appConfigurationManager.getConfiguration('ux.calendar.yearRange').value).join(':');
  }

  writeValue(value: any): void {
    let parsedValue: string;

    if (_isString(value)) {
      parsedValue = value.substring(0, 10);
    } else if (value instanceof Date) {
      parsedValue = value.toISOString().substring(0, 10);
    } else {
      parsedValue = value;
    }

    super.writeValue(parsedValue);
  }
}
