import { isNumber as _isNumber, isString as _isString } from 'lodash';
export class Region {
  protected id: number;
  protected name: string;

  constructor(data: any) {
    this.setId(data.id);
    this.setName(data.name);
  }

  /**
   * Gets the ID.
   *
   * @returns number
   */
  getId(): number {
    return this.id;
  }

  /**
   * Gets the Name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Sets the id.
   * @throws ErrorHelper if the given data has an invalid name.
   * @returns void
   */
   protected setId(id: number): void {
    if (_isNumber(id)) {
      this.id = id;
    } else {
      throw new Error('Invalid ID given.');
    }
  }

  /**
   * Sets the name.
   * @throws ErrorHelper if the given data has an invalid name.
   * @returns void
   */
  protected setName(name: string): void {
    if (_isString(name)) {
      this.name = name.trim();
    } else {
      throw new Error('Invalid name given.');
    }
  }
}
