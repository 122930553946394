import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';


export abstract class BoltInputField implements ControlValueAccessor {
  @Input() horizontalMode: boolean = true;
  @Input() requiredByDefault: boolean = false;
  @Input() label: string;
  @Input() maxWidth: string = '100%';
  @Input() placeHolder: string = '';
  @Input() showError: boolean = true;
  @Input() disabled: boolean = false;
  @Output('changed') changeEvent: EventEmitter<any> = new EventEmitter();

  abstract value: any;
  protected onModelChange: CallableFunction;
  protected onModelTouched: CallableFunction;

  constructor(protected ngControl: NgControl) {
    this.setValueAccessor();
  }

  /**
   * Indicates if the ngControl is required by default.
   *
   * @returns boolean
   */
  isRequiredByDefault(): boolean {
    const isIt: boolean = this.requiredByDefault || this.ngControl.dirty;
    return isIt;
  }

  /**
   * Sets the value accessor.
   *
   * @returns void
   */
  setValueAccessor(): void {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Writes a value.
   *
   * @param value any
   * @returns void
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Registers on change event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnChange(fn: CallableFunction): void {
    this.onModelChange = fn;
  }

  /**
   * Registers on touch event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnTouched(fn: CallableFunction): void {
    this.onModelTouched = fn;
  }

  /**
   * Set the disabled state.
   *
   * @param val boolean
   * @returns void
   */
  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  /**
   * Emits the change.
   *
   * @param value any
   * @returns void
   */
  onChange(value: any): void {
    if (this.onModelChange) {
      this.onModelChange(value);
      this.changeEvent.emit(value);
    }
  }
}
