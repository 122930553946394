export enum TypeEnum {
  account = 'account',
  aspectRatio = 'aspectRatio',
  productionCompany = 'productionCompany',
  dubbingStudios = 'dubbingStudios',
  functionalMetadata = 'functionalMetadata',
  genre = 'genre',
  language = 'language',
  primaryProductAssociation = 'primaryProductAssociation',
  productType = 'productType',
  rating = 'rating',
  ratingSystem = 'ratingSystem',
  ratingSystemReason = 'ratingSystemReason',
  secondaryProductAssociation = 'secondaryProductAssociation',
  territory = 'territory',
  region = 'region'
}
