import { Component } from '@angular/core';
import { BoltMoratoriumComponent } from '../bolt-moratorium.component';


@Component({
  selector: 'bolt-moratorium-mark',
  templateUrl: './bolt-moratorium-mark.html',
  styleUrls: ['./bolt-moratorium-mark.scss']
})
export class BoltMoratoriumMarkComponent extends BoltMoratoriumComponent {
  constructor() {
    super();
  }
}
