import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bolt-countdown-button',
  templateUrl: 'bolt-countdown-button.html',
  styleUrls: ['bolt-countdown-button.scss']
})
export class BoltCountdownButtonComponent {
  @Input() label: string;
  @Input() styleClass: string;

  @Input()
  set seconds(seconds: number) {
    this._seconds = seconds;

    this.stop();
    this.start();
  }

  get seconds(): number {
    return this._seconds;
  }

  @Output('clicked') clickEvent: EventEmitter<void>;

  protected _seconds: number;
  protected interval: any;

  constructor() {
    this.clickEvent = new EventEmitter();
    this.seconds = 0;
  }

  /**
   * Indicate if the button is disabled
   *
   * @returns boolean
   */
  hasDisabled(): boolean {
    return this.seconds === -1 || this.seconds > 0;
  }

  /**
   * Notifies the click event if the seconds are equal to zero
   *
   * @return void
   */
  notifyClick(): void {
    if (this.seconds === 0) {
      this.clickEvent.emit();
    }
  }

  /**
   * Starts the counter and set the interval, if the store seconds are greater than zero
   *
   * @return void
   */
  protected start(): void {
    if (this.seconds > 0) {
      this.interval = setInterval(
        () => {
          this.seconds--;

          if (this.seconds === 0) {
            this.stop();
          }
        },
        1000
      );
    } else if (this.seconds < -1) {
      throw Error('Invalid seconds given, expecting -1 or higher.');
    }
  }

  /**
   * Stops the counter and cleans the interval
   *
   * @return void
   */
  protected stop(): void {
    clearInterval(this.interval);
    this.interval = undefined;
  }
}
