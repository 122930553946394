import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-no-data-mark',
  templateUrl: 'bolt-no-data-mark.html',
  styleUrls: ['bolt-no-data-mark.scss']
})
export class BoltNoDataMarkComponent {
  @Input() hideLegend: boolean;
  @Input() legend: string;
  @Input() show: boolean;

  constructor() {
    this.hideLegend = false;
    this.legend = 'No Data';
    this.show = false;
  }
}
