import { isArray as _isArray, isObject as _isObject } from 'lodash';


export abstract class JsonFlatter {
  /**
   * Takes all the properties of the given object data and returns an array of [string, any].
   *
   * @param data any
   * @returns Array<[string, any]>
   */
  static flat(data: any): Array<[string, any]> {
    const output: Array<[string, any]> = [];

    this.fill(output, data);

    return output;
  }

  /**
   * Fills the given store (using recursion) with the given data and prefix.
   *
   * @param store Array<[string, any]>
   * @param data any
   * @param prefix string
   * @returns void
   */
   protected static fill(store: Array<[string, any]>, data: any, prefix: string = ''): void {
    Object.keys(data).forEach(
      (attribute: string) => {
        const key: string = (prefix.length > 0) ? `${prefix}.${attribute}` : attribute;
        const value: any = data[attribute];

        if (_isArray(value) || _isObject(value)) {
          this.fill(store, value, key);
        } else {
          store.push([key, value]);
        }
      }
    );
  }
}
