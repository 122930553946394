import {
  Component, ElementRef, Renderer2, ChangeDetectorRef, IterableDiffers, Input, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges,
  forwardRef, AfterViewInit
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtils } from 'primeng/utils';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, map, pairwise } from 'rxjs/operators';
import { isObject as _isObject, isFunction as _isFunction, isString as _isString, isUndefined as _isUndefined } from 'lodash';


@Component({
  selector: 'bolt-multi-field-autocomplete',
  templateUrl: 'bolt-multi-field-autocomplete.html',
  styleUrls: ['./bolt-multi-field-autocomplete.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoltMultiFieldAutocompleteComponent),
      multi: true
    }
  ]
})
export class BoltMultiFieldAutocompleteComponent extends AutoComplete implements AfterViewInit, OnChanges, ControlValueAccessor {
  @Input() autoFocus: boolean;
  @Input() checkbox: boolean = false;
  @Input() checkboxLabel: string;
  @Input('checkboxDefault') checkboxValue: boolean = false;
  @Input() fields: string[];
  @Input() secondaryFields: string[];
  @Input() fieldToShow: string;
  @Input() headers: string[];
  @Input() paginationOn: boolean;
  @Input() scrollFinish: boolean;
  @Input() scrollLoading: boolean;
  @Input() showBottomMessage: boolean;
  @Input() showSelectedOption: boolean;
  @Input() suggestionsLoading: boolean;

  @Output('scrollDownBottom') protected scrollDownBottomEvent: EventEmitter<undefined>;
  @Output('checkboxChanged') protected checkboxChangedEvent: EventEmitter<boolean>;

  @ViewChild('in') protected input: ElementRef;
  @ViewChild('panel') protected panel: ElementRef;

  protected scrollEvent: Observable<any>;
  protected showPanel: boolean;
  protected panelVisibleNotifier: BehaviorSubject<boolean>;
  protected secondaryFieldsMap: Map<string, string>;

  constructor(
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    differs: IterableDiffers
  ) {
    super(el, renderer, cd, differs);
    this.initialize();
  }

  ngAfterViewInit() {
    this.setupPanel();
    this.secondaryFieldsMap = this.getSecondaryFieldsMap(this.secondaryFields);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.autoFocus)) {
      if (changes.autoFocus.currentValue) {
        this.ensureFocus();
      }
    }
  }

  /**
   * Builds the click event listeners
   *
   * @returns void
   */
  bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event: any) => {
        if (event.which === 3 || this.isNotClickeable(event.path)) {
          return;
        }

        if (this.inputClick) {
          this.inputClick = false;
        } else {
          this.hide();
        }

        this.cd.markForCheck();
      });
    }
  }

  /**
   * Gets the secondary Field value given an option and the primary field name
   *
   * @param option any
   * @param field string
   * @returns string
   */
  getSecondaryInfo(option: any, field: string): string {
    const secondaryFieldName: string = this.secondaryFieldsMap.get(field);
    const secondaryFieldValue: string = option[secondaryFieldName];
    return secondaryFieldValue;
  }

  /**
   * Handle the suggestions changes.
   *
   * @returns void
   */
  handleSuggestionsChange(): void {
    if (this._suggestions != null) {
      this.highlightOption = null;

      if (this._suggestions.length) {
        this.noResults = false;
        this.show();
        this.suggestionsUpdated = true;

        if (this.autoHighlight) {
          this.highlightOption = this._suggestions[0];
        }
      } else {
        this.noResults = true;

        if (this.emptyMessage) {
          this.show();
          this.suggestionsUpdated = true;
        } else {
          this.hide();
        }
      }
    }
  }

  /**
   * Indicates if it has to display the bottom message.
   *
   * @returns boolean
   */
  hasDisplayBottomMessage(): boolean {
    return this.showBottomMessage;
  }

  /**
   * Indicates if it has to display the checkbox.
   *
   * @returns boolean
   */
  hasDisplayCheckbox(): boolean {
    return this.checkbox;
  }

  /**
   * Indicates if it has to display the checkbox label.
   *
   * @returns boolean
   */
  hasDisplayCheckboxLabel(): boolean {
    return _isString(this.checkboxLabel);
  }

  /**
   * Indicates if it has to display the no more results message.
   *
   * @returns boolean
   */
  hasDisplayNoMoreResults(): boolean {
    const hasIt: boolean = this.scrollFinish && !this.scrollLoading;
    return hasIt;
  }

  /**
   * Indicates if it has to display the panel.
   *
   * @returns boolean
   */
  hasDisplayPanel(): boolean {
    return this.showPanel;
  }

  /**
   * Indicates if it has to display the scroll spinner.
   *
   * @returns boolean
   */
  hasDisplayScrollSpinner(): boolean {
    return this.scrollLoading;
  }

  /**
   * Indicates if it has to display the suggestions spinner.
   *
   * @returns boolean
   */
  hasDisplaySuggestionsSpinner(): boolean {
    return this.suggestionsLoading;
  }

  /**
   * Verifies if a given field have secondary info to be shown
   *
   * @param field string
   * @returns boolean
   */
  hasSecondaryInfo(field: string): boolean {
    const hasIt: boolean = this.secondaryFieldsMap && this.secondaryFieldsMap.has(field);
    return hasIt;
  }

  /**
   * Hide the suggestions panel.
   *
   * @returns void
   */
  hide(): void {
    this.showPanel = false;
    super.hide();
  }

  /**
   * Resolves the value for the given field.
   * The '$' symbol denotes that the expression given contains a child object
   * The '>' symbol denotes that the expression given contains a function
   * The ':' symbol is used to separate all the params in a function
   *
   * @param option object
   * @param field string
   * @returns string
   */
  resolveValue(option: object, field: string): string {
    const contextObject: object = this.getContextObject(option, field);
    const value: string = this.applyFieldParams(contextObject, field);

    return value;
  }

  /**
   * Fill the input with the selected option or reset the input, and emit the onSelect event
   *
   * @param option any
   * @returns void
   */
  selectItem(option: any): void {
    if (this.showSelectedOption) {
      this.setValue(option);
      this.onModelChange(this.value);
    } else {
      this.resetInput();
    }

    this.onSelect.emit(option);
    this.focusInput();
  }

  /**
   * Set the disable state value.
   *
   * @param isDisabled boolean
   * @returns void
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Shows the suggestions panel.
   *
   * @returns void
   */
  show(): void {
    if (this.input && !this.showPanel && (this.input.nativeElement.value !== '')) {
      this.showPanel = true;
      this.panelVisibleNotifier.next(true);
      this.bindDocumentClickListener();
      super.show();
    }
  }

  /**
   * Toggles the current checkbox value.
   *
   * @returns void
   */
  toggleCheckboxValue(): void {
    this.checkboxValue = !this.checkboxValue;
    this.checkboxChangedEvent.emit(this.checkboxValue);
  }

  /**
   * Changes the input value when the binding ngModel changes.
   *
   * @param value any
   * @returns void
   */
  writeValue(value: any): void {
    if (_isObject(value)) {
      this.setValue(value);
    } else {
      if (!_isString(value)) {
        this.resetInput();
      }
    }
  }

  /**
   * Checks the scroll event data.
   *
   * @param data any[]
   * @returns void
   */
  protected checkScrollDown(data: any[]): void {
    if (this.isUserScrollingDown(data) && this.isScrollEnd(data[1])) {
      this.scrollDownBottomEvent.emit();
    }
  }

  /**
   * Ensures the autofocus on the query input.
   *
   * @returns void
   */
  protected ensureFocus(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.autoFocus = false;
    this.panelVisibleNotifier = new BehaviorSubject(undefined);
    this.scrollDownBottomEvent = new EventEmitter();
    this.checkboxChangedEvent = new EventEmitter();
    this.showBottomMessage = false;
    this.showPanel = false;
    this.suggestionsLoading = false;
  }

  /**
   * Indicates if the given array of elements has a HTMLElement with id
   * 'bolt-multi-field-autocomplete-headers' or 'spinner'
   *
   * @param path any[]
   * @returns boolean
   */
  protected isNotClickeable(path: any[]): boolean {
    const isIt: boolean = _isUndefined(path)
      ? false
      : path.find(
        elem => elem.id === 'bolt-multi-field-autocomplete-headers' ||
          elem.id === 'spinner' ||
          elem.id === 'checkbox'
      );

    return isIt;
  }

  /**
   * Indicates if the user is scrolling down.
   *
   * @param positions any[]
   * @returns boolean
   */
  protected isUserScrollingDown(positions: any[]): boolean {
    const isIt: boolean = positions[0].scrollTop < positions[1].scrollTop;
    return isIt;
  }

  /**
   * Indicates if the user scrolled until the bottom
   *
   * @param position any
   * @returns boolean
   */
  protected isScrollEnd(position: any): boolean {
    const isIt: boolean = ((position.scrollTop + position.clientHeight) / position.scrollHeight) === 1;
    return isIt;
  }

  /**
   * Set the given value as the current input value.
   *
   * @param value any
   * @returns void
   */
  protected setValue(value: any): void {
    const inputValue = this.fieldToShow ? ObjectUtils.resolveFieldData(value, this.fieldToShow) : value;

    if (_isUndefined(this.inputEL)) {
      this.cd.detectChanges();
    }

    this.inputEL.nativeElement.value = inputValue;
    this.value = value;
  }

  /**
   * Set up the suggestions panel.
   *
   * @returns void
   */
  protected setupPanel(): void {
    this.scrollEvent = fromEvent(this.panel.nativeElement, 'scroll');

    this.scrollEvent.pipe(
      map(
        (data: any) => {
          const mappedData: any = {
            scrollHeight: data.target.scrollHeight,
            scrollTop: data.target.scrollTop,
            clientHeight: data.target.clientHeight
          };

          return mappedData;
        }
      ),
      pairwise()
    ).subscribe(
      (data: any) => {
        this.checkScrollDown(data);
      }
    );

    this.panelVisibleNotifier.asObservable().pipe(debounceTime(0)).subscribe(
      () => {
        this.panel.nativeElement.scrollTop = 0;
      }
    );
  }

  /**
   * Resets the input
   *
   * @returns void
   */
  protected resetInput(): void {
    if (_isUndefined(this.inputEL)) {
      this.cd.detectChanges();
    }

    this.inputEL.nativeElement.value = '';
  }

  /**
   * Get the object that will be used to resolve the value
   *
   * @param option object
   * @param field string
   * @throws ErrorHelper
   * @returns object
   */
  protected getContextObject(option: object, field: string) {
    if (field.includes('$')) {
      const childName: string = field.split('$')[0];
      const child: any = option[childName];

      if (_isObject(child)) {
        return child;
      } else {
        throw new Error('The expected param before the "$" symbol should be an object.');
      }
    } else {
      return option;
    }
  }

  /**
   * Applies the params provided in the field to the object given
   *
   * @param object object
   * @param field string
   * @throws ErrorHelper
   * @returns string
   */
  protected applyFieldParams(contextObject: object, field: string): string {
    let value: string;

    if (field.includes('>')) {
      const functionName: string = field.substring(field.indexOf('$') + 1, field.indexOf('>'));
      const method: CallableFunction = contextObject[functionName];

      if (_isFunction(method)) {
        const params: string[] = field.substring(field.indexOf('>') + 1).split(':');

        if (params.length > 1 || (params.length === 1 && params[0].length > 0)) {
          value = method.apply(contextObject, params);
        } else {
          value = contextObject[functionName]();
        }
      } else {
        throw new Error('The param before the ">" symbol should be a function.');
      }
    } else {
      value = contextObject[field.substring(field.indexOf('$') + 1)];
    }

    return value;
  }

  /**
   * Converts the given Secondary Fields array into a map
   *
   * @param secondaryFields string[]
   * @returns Map<string, string>
   */
  protected getSecondaryFieldsMap(secondaryFields: string[]): Map<string, string> {
    const fieldsMap: Map<string, string> = new Map();

    if (!_isUndefined(secondaryFields)) {
      secondaryFields.forEach(fieldArrange => {
        const values = fieldArrange.split(':');
        fieldsMap.set(values[0], values[1]);
      });
    }

    return fieldsMap;
  }
}
