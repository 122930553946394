import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { OutageNotificationConfig } from '../../models/outage-notification-config/outage-notification-config.model';

@Component({
  selector: 'bolt-outage-notification',
  templateUrl: 'bolt-outage-notification.html',
  styleUrls: ['bolt-outage-notification.scss']
})
export class BoltOutageNotificationComponent implements OnInit {
  @Input() config: OutageNotificationConfig;
  @HostBinding('class.hidden') hidden: boolean;
  message: string;

  private readonly dismissDateKey = 'BoltOutageNotificationDate';
  private duration: number;

  constructor() {
    this.hide();
  }

  ngOnInit(): void {
    this.message = this.config.getMessage();
    this.duration = this.config.getDuration();
    this.hidden = this.config.isHidden();

    this.updateState();
  }

  /**
   * Handles the dismiss action
   *
   * @returns void
   */
  handleClickDismiss(): void {
    if (this.saveDismissDate()) {
      this.hide();
    }
  }

  /**
   * Retrieves the previously stored date if possible
   *
   * @returns Date | undefined
   */
  private retrieveDismissDate(): Date | undefined {
    let previousDate: Date | undefined;

    try {
      const storedDateString: string = localStorage.getItem(this.dismissDateKey);

      if (storedDateString) {
        previousDate = new Date(storedDateString);
      }
    } catch (error) {
      previousDate = undefined;
    }

    return previousDate;
  }

  /**
   * Saves the dismiss date into LocalStorage
   *
   * @returns boolean
   */
  private saveDismissDate(): boolean {
    const dismissDate = new Date();

    try {
      localStorage.setItem(this.dismissDateKey, dismissDate.toString());
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Verifies if the notification should be displayed
   *
   * @returns void
   */
  private updateState(): void {
    const storedDate = this.retrieveDismissDate();

    if (!this.hidden && storedDate) {
      const currentDate = new Date();
      const difference = Math.round((currentDate.getTime() - storedDate.getTime()) / 1000);

      if (difference <= this.duration) {
        this.hide();
      }
    }
  }

  /**
   * Hides the notification
   *
   * @returns void
   */
  private hide(): void {
    this.hidden = true;
  }
}
