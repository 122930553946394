import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { isNull as _isNull, isString as _isString } from 'lodash';


@Component({
  selector: 'bolt-form-field-error-messages',
  templateUrl: 'bolt-form-field-error-messages.html',
  styleUrls: ['bolt-form-field-error-messages.scss']
})
export class BoltFormFieldErrorMessagesComponent {
  @Input() customErrors: Map<string, string>;
  @Input() requiredByDefault: boolean;
  @Input() expectedPattern: string;
  @Input() errors: ValidationErrors;
  @Input() label: string;

  constructor() {
    this.customErrors = new Map();
  }

  /**
   * Get the current custom errors keys
   *
   * @returns string[]
   */
  getCurrentErrorsKey(): string[] {
    const keys: string[] = Array.from(this.customErrors.keys()).filter(
      (key: string) => this.hasError(key)
    );

    return keys;
  }

  /**
   * Indicates if it has to display the email error.
   *
   * @returns boolean
   */
  hasDisplayEmailError(): boolean {
    return this.hasError('email');
  }

  /**
   * Indicates if it has to display the maxlength error.
   *
   * @returns boolean
   */
  hasDisplayMaxLengthError(): boolean {
    return this.hasError('maxlength');
  }

  /**
   * Indicates if it has to display the minlength error.
   *
   * @returns boolean
   */
  hasDisplayMinLengthError(): boolean {
    return this.hasError('minlength');
  }

  /**
   * Indicates if it has to display the pattern error.
   *
   * @returns boolean
   */
  hasDisplayPatternError(): boolean {
    const hasIt: boolean = this.hasError('pattern') && _isString(this.expectedPattern);
    return hasIt;
  }

  /**
   * Indicates if it has to display the required error.
   *
   * @returns boolean
   */
  hasDisplayRequiredError(): boolean {
    const hasIt: boolean =
      this.hasError('required') &&
      this.requiredByDefault;

    return hasIt;
  }

  /**
   * Indicates if the current control has the given error.
   *
   * @param key string
   * @returns boolean
   */
  hasError(key: string): boolean {
    const hasIt: boolean =  Object.keys(this.errors).some((error: string) => error === key);
    return hasIt;
  }

  /**
   * Indicates if the current control has errors.
   *
   * @returns boolean
   */
  hasErrors(): boolean {
    return !_isNull(this.errors);
  }

  /**
   * Get the error for the given validator of the current field.
   *
   * @param validator string
   * @returns any
   */
  getError(validator: string): any {
    return this.errors[validator];
  }
}
