import { isUndefined as _isUndefined } from 'lodash';

export interface StormListItemInterface {
  id: number | string;
  value: any;
  disabled?: boolean;
  selected?: boolean;
}

export class StormListItem implements StormListItemInterface {
  protected _id: number | string;
  protected _value: any;
  protected _disabled: boolean;
  protected _selected: boolean;

  constructor(protected data: any) {
    this._id = data.id;
    this._value = data.value;
    this._disabled = _isUndefined(data.disabled) ? false : data.disabled;
    this._selected = _isUndefined(data.selected) ? false : data.selected;
  }

  get id(): number | string {
    return this._id;
  }

  set id(value: number | string) {
    this._id = value;
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }
}
