import { Injectable } from '@angular/core';

import { EnvironmentEnum } from '../../enums/environment/environment.enum';
import { WindowWrapperService } from '../window-wrapper/window-wrapper.service';


@Injectable()
export class EnvironmentService {
  protected current: EnvironmentEnum;
  protected name: string;

  constructor(protected windowWrapperService: WindowWrapperService) {
    this.discover();
  }

  /**
   * Gets the name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Indicates if it is "INT".
   *
   * @returns boolean
   */
  isInt(): boolean {
    return this.is(EnvironmentEnum.INT);
  }

  /**
   * Indicates if it is "LOCAL".
   *
   * @returns boolean
   */
  isLocal(): boolean {
    return this.is(EnvironmentEnum.LOCAL);
  }

  /**
   * Indicates if it is "PROD".
   *
   * @returns boolean
   */
  isProd(): boolean {
    return this.is(EnvironmentEnum.PROD);
  }

  /**
   * Indicates if it is "QAT".
   *
   * @returns boolean
   */
  isQat(): boolean {
    return this.is(EnvironmentEnum.QAT);
  }

  /**
   * Indicates if it is "SND"
   *
   * @returns boolean
   */
  isSnd(): boolean {
    return this.is(EnvironmentEnum.SND);
  }

  /**
   * Indicates if it is "STG".
   *
   * @returns boolean
   */
  isStg(): boolean {
    return this.is(EnvironmentEnum.STG);
  }

  /**
   * Indicates if the given value is the stored current.
   *
   * @returns boolean
   */
  protected is(value: EnvironmentEnum): boolean {
    const isIt: boolean = (this.current === value);
    return isIt;
  }

  /**
   * Discovers the current environment and sets it.
   *
   * @returns void
   */
  protected discover(): void {
    const host: string = this.windowWrapperService.getLocation().host;

    if (host.startsWith('localhost:')) {
      this.current = EnvironmentEnum.LOCAL;
    } else {
      const environment: string = Object.keys(EnvironmentEnum).find(
        (key: string) => {
          const env: EnvironmentEnum = EnvironmentEnum[key];
          const matched: boolean = (host.startsWith(`${env}-`) || host.startsWith(`bolt.${env}.`));

          return matched;
        }
      );

      this.current = environment ? EnvironmentEnum[environment] : EnvironmentEnum.PROD;
    }

    this.name = this.current;
  }
}
