export class EntryMock {
  protected _key: string;
  protected _value: any;

  constructor(key: string, value: any) {
    this.initialize(key, value);
  }

  get value(): any {
    return this._value;
  }

  get key(): string {
    return this._key;
  }

  /**
   * Initializes the instance.
   *
   * @param key string
   * @param value any
   * @return void
   */
  protected initialize(key: string, value: any): void {
    this._value = value;
    this._key = key;
  }
}
