import { Component } from '@angular/core';
import { BoltMoratoriumComponent } from '../bolt-moratorium.component';


@Component({
  selector: 'bolt-moratorium-label',
  templateUrl: './bolt-moratorium-label.html',
  styleUrls: ['./bolt-moratorium-label.scss']
})
export class BoltMoratoriumLabelComponent extends BoltMoratoriumComponent {
  constructor() {
    super();
  }
}
