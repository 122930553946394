import { Account } from '../models/account/account.model';
import { AspectRatio } from '../models/aspect-ratio/aspect-ratio.model';
import { Company } from '../models/company/company.model';
import { Country } from '../models/country/country.model';
import { DubbingStudio } from '../models/dubbing-studio/dubbing-studio.model';
import { FunctionalMetadata } from '../models/functional-metadata/functional-metadata.model';
import { Genre } from '../models/genre/genre.model';
import { Language } from '../models/language/language.model';
import { TypeEnum } from '../models/list/type.enum';
import { PrimaryProductAssociation } from '../models/primary-product-association/primary-product-association.model';
import { ProductType } from '../models/product-type/product-type.model';
import { RatingSystemReason } from '../models/rating-system-reason/rating-system-reason.model';
import { RatingSystem } from '../models/rating-system/rating-system.model';
import { Rating } from '../models/rating/rating.model';
import { Region } from '../models/region/region.model';
import { SecondaryProductAssociation } from '../models/secondary-product-association/secondary-product-association.model';


export abstract class ItemHelper {

  /**
   * Creates an Item Instance according to the given type
   *
   * @param listType TypeEnum
   * @param data any
   * @returns any
   */
  static createItem(listType: TypeEnum, data: any): any {
    switch (listType) {
      case TypeEnum.account:
        return new Account(data.id, data.name, data.code);
      case TypeEnum.aspectRatio:
        return new AspectRatio(data.id, data.name);
      case TypeEnum.dubbingStudios:
        return new DubbingStudio(data);
      case TypeEnum.functionalMetadata:
        return new FunctionalMetadata(data);
      case TypeEnum.genre:
        return new Genre(data.id, data.name, data.titleType, data.genreType, data.territoryAccountUnits, data.accounts);
      case TypeEnum.language:
        return new Language(data.id, data.name, data.alternateName, data.localeLanguage, data.languageType, data.iso6391);
      case TypeEnum.primaryProductAssociation:
        return new PrimaryProductAssociation(data);
      case TypeEnum.productType:
        return new ProductType(data.id, data.name, data.code);
      case TypeEnum.productionCompany:
        return new Company(data.id, data.name);
      case TypeEnum.rating:
        return new Rating(data.id, data.name, data.description, data.ratingSystemId, data.ratingSystem);
      case TypeEnum.ratingSystem:
        return new RatingSystem(data.id, data.name, data.type);
      case TypeEnum.ratingSystemReason:
        return new RatingSystemReason(data.id, data.name, data.ratingSystems);
      case TypeEnum.secondaryProductAssociation:
        return new SecondaryProductAssociation(data);
      case TypeEnum.territory:
        return new Country(data);
      case TypeEnum.region:
        return new Region(data);
      default:
        throw new Error(`Invalid list type given: ${listType}`);
    }
  }
}

