import { Injectable } from '@angular/core';
import { JsonFlatter } from '@bolt/ui-shared/data-transform';
import { isObject as _isObject } from 'lodash';


@Injectable({ providedIn: 'root' })
export class AppRoutesService {
  protected routes: Map<string, string>;

  constructor() {
    this.routes = new Map();
  }

  get length(): number {
    return this.routes.size;
  }

  /**
   * Returns the route with the given key.
   *
   * @param key string
   * @throws Error
   * @returns string
   */
  get(key: string): string {
    if (this.routes.has(key)) {
      return this.routes.get(key);
    } else {
      throw new Error(`Invalid key given for a route: ${key}`);
    }
  }

  /**
   * Indicates if there is no routes.
   *
   * @returns boolean
   */
  isEmpty(): boolean {
    const isIt: boolean = this.length === 0;
    return isIt;
  }

  /**
   * Loads the routes from the given JSON.
   *
   * @param json object
   * @throws Error
   * @returns void
   */
  loadRoutes(json: object): void {
    if (_isObject(json) && Object.keys(json).length > 0) {
      try {
        this.routes.clear();

        JsonFlatter.flat(json).forEach(
          ([key, value]) => {
            this.routes.set(key, value);
          }
        );
      } catch (err) {
        throw new Error(`Failed loading routes from the given JSON: ${err.message}`);
      }
    } else {
      throw new Error('The given JSON should be a non-empty object.');
    }
  }
}
