import { HttpParams } from '@angular/common/http';
import { cloneDeep as _cloneDeep, isObject as _isObject, isUndefined as _isUndefined, isNull as _isNull } from 'lodash';


export class UrlSearchParams {
  protected params: HttpParams;

  constructor() {
    this.params = new HttpParams();
  }

  /**
   * Returns a new instance from the given source.
   *
   * @param source any
   * @returns UrlSearchParams
   */
  static from(source: any): UrlSearchParams {
    if (_isObject(source)) {
      if (source instanceof UrlSearchParams) {
        return _cloneDeep(source);
      } else {
        const params: UrlSearchParams = new UrlSearchParams();

        Object.keys(source).forEach(
          (key: string) => {
            params.set(key, source[key]);
          }
        );

        return params;
      }
    } else {
      throw new Error('Invalid value given for a source');
    }
  }

  /**
   * Appends a new value to existing values for a parameter.
   *
   * @param param string
   * @param value string
   * @returns void
   */
  append(param: string, value: string): void {
    this.params = this.params.append(param, value);
  }

  /**
   * Removes a given value or all values from a parameter.
   *
   * @param param string
   * @param value string
   * @returns void
   */
  delete(param: string, value?: string): void {
    this.params = this.params.delete(param, value);
  }

  /**
   * Retrieves the first value for a parameter or `null` if the parameter is not present.
   *
   * @param param string
   * @returns void
   */
  get(param: string): string | null {
    return this.params.get(param);
  }

  /**
   * Retrieves all values for a parameter or `null` if the parameter not present.
   *
   * @param param string
   * @returns string[]
   */
  getAll(param: string): string[] | null {
    return this.params.getAll(param);
  }

  /**
   * Indicates if there are one or more values for the given parameter.
   *
   * @param param string
   * @returns boolean
   */
  has(param: string): boolean {
    return this.params.has(param);
  }

  keys(): string[] {
    return this.params.keys();
  }

  /**
   * Set the value for a parameter.
   *
   * @param param string
   * @param value string
   * @returns void
   */
  set(param: string, value: string): void {
    this.params = this.params.set(param, value);
  }

  /**
   * Serializes the parameters to an encoded string, where key-value pairs (separated by `=`) are separated by `&`.
   *
   * @returns string
   */
  toString(): string {
    const output: string[] = [];

    this.keys().forEach(
      (key: string) => {
        const value: string = this.get(key);

        if (!_isUndefined(value) && !_isNull(value)) {
          output.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
    );

    return output.join('&');
  }
}
