export interface CompanyInterface {
  id: number;
  name: string;
}

export class Company implements CompanyInterface {

  constructor(
    public id: number,
    public name: string
  ) { }

  toString() {
    return this.name;
  }
}
