import { CommonModule } from '@angular/common';
import { CommonModule as BoltCommonModule } from '@bolt/ui-shared/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RadioButtonModule, TooltipModule, InputSwitchModule } from 'primeng';
import { CheckboxModule } from 'primeng/checkbox';

import { BoltFunctionalMetadataComponent } from './components/bolt-functional-metadata/bolt-functional-metadata.component';
import {
  BoltFunctionalMetadataDetailsComponent
} from './components/bolt-functional-metadata-details/bolt-functional-metadata-details.component';
import { BoltAuthoritativeRatingReasonComponent } from './components/bolt-authoritative-rating-reason/bolt-authoritative-rating-reason.component';
import { BoltAuthoritativeRatingComponent } from './components/bolt-authoritative-rating/bolt-authoritative-rating.component';
import { BoltRatingComponent } from './components/bolt-rating/bolt-rating.component';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioButtonModule,
    TooltipModule,
    InputSwitchModule
  ],
  declarations: [
    BoltAuthoritativeRatingComponent,
    BoltAuthoritativeRatingReasonComponent,
    BoltFunctionalMetadataComponent,
    BoltFunctionalMetadataDetailsComponent,
    BoltRatingComponent,
  ],
  exports: [
    BoltAuthoritativeRatingComponent,
    BoltAuthoritativeRatingReasonComponent,
    BoltFunctionalMetadataComponent,
    BoltFunctionalMetadataDetailsComponent,
    BoltRatingComponent
  ],
  providers: []
})
export class TitleModule { }
