import { isNumber as _isNumber, isString as _isString } from 'lodash';

import { AccountInterface } from './account.interface';


export class Account implements AccountInterface {
  static readonly ALL_ID: number = 0;
  static readonly ALL_VALUE: string = '*';
  static readonly LOCALE_POSITION: number = 3;
  static readonly DISNEYSVOD_ID = 1001;
  static readonly DISNEYSVOD_VALUE = 'DISNEYSVOD';

  protected _code: string;
  protected _id: number;
  protected _name: string;

  constructor(id: number, name: string, code: string = '') {
    this._id = id;
    this._name = name;
    this._code = code;
  }

  /**
   * Indicates if the given account value is All.
   *
   * @param account number | Account | string
   * @returns boolean
   */
  static isAll(account: number | Account | string): boolean {
    return Account.is(account, Account.ALL_ID, Account.ALL_VALUE);
  }

  /**
   * Indicates if the given account value is Disney SVOD.
   *
   * @param account number | Account | string
   * @returns boolean
   */
  static isDisneySvod(account: number | Account | string): boolean {
    return Account.is(account, Account.DISNEYSVOD_ID, Account.DISNEYSVOD_VALUE);
  }

  get code(): string {
    return this._code;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Indicates if it is for "All".
   *
   * @returns boolean
   */
  isAll(): boolean {
    return Account.isAll(this.id);
  }

  /**
   * Indicates if it is for "Disney SVOD".
   *
   * @returns boolean
   */
  isDisneySvod(): boolean {
    return Account.isDisneySvod(this.id);
  }

  toLowerCase() {
    return this.name.toLowerCase();
  }

  toString() {
    return this.name;
  }

  /**
   * Indicates if the given account matches with the expected ID or value.
   *
   * @param account number | string | Account
   * @param expectedId number
   * @param expectedValue string
   *
   * @returns boolean
   */
  protected static is(account: number | string | Account, expectedId: number, expectedValue: string): boolean {
    let isIt: boolean;

    if (_isNumber(account)) {
      isIt = account === expectedId;
    } else if (account instanceof Account) {
      isIt = (<Account>account).id === expectedId;
    } else if (_isString(account)) {
      isIt = account === expectedValue;
    } else {
      isIt = false;
    }

    return isIt;
  }
}
