export enum MappableAttributeEnum {
  countryOfOriginId = 'countryOfOriginId',
  functionalMetadata = 'functionalMetadata',
  genreId = 'genreId',
  originalSpokenLanguageId = 'originalSpokenLanguageId',
  originalLanguageId = 'originalLanguageId',
  primaryProductAssociation = 'primaryProductAssociation',
  productionCompanyId = 'productionCompanyId',
  productionCompanyIds = 'productionCompanyIds',
  ratingId = 'ratingId',
  ratingSystemReasonId = 'ratingSystemReasonId',
  secondaryProductAssociation = 'secondaryProductAssociation',
  prefixId = 'prefixId',
  suffixId = 'suffixId'
}
