import { Component, Input } from '@angular/core';
import { isObject as _isObject } from 'lodash';


@Component({
  selector: 'bolt-length-counter',
  templateUrl: 'bolt-length-counter.html',
  styleUrls: ['bolt-length-counter.scss']
})
export class BoltLengthCounterComponent {
  @Input() label: string;
  @Input() length: number;
  @Input() limit: number;

  readonly defaultCounter: number = 0;

  constructor() {
    this.label = '';
  }
}
