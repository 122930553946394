export class UserRole {
  protected _id: number;
  protected _name: string;
  protected _notes: string;

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(attributes);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  /**
   * Set the attributes.
   *
   * @param attributes \{ [attr: string]: any }
   * @returns void
   */
  setAttributes(attributes?: { [attr: string]: any }): UserRole {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }

    return this;
  }

  /**
   * Returns a new raw object with the inner content.
   *
   * @returns object
   */
  getRawObject(): object {
    const output: object = new Object();
    const names: string[] = Object.getOwnPropertyNames(UserRole.prototype);

    const getters: string[] = names.filter(
      (name: string) => {
        const result: boolean = !!Object.getOwnPropertyDescriptor(UserRole.prototype, name).get;
        return result;
      }
    );

    getters.forEach(
      (key: string) => {
        if (this[key] !== undefined) {
          output[key] = this[key];
        }
      }
    );

    return output;
  }

  toString(): string {
    return this.name;
  }
}
