import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { Attribute } from '../../models/functional-metadata/attribute/attribute.model';
import { FunctionalMetadataEnum } from '../../models/functional-metadata/functional-metadata.enum';
import { LevelModeEnum } from '../../models/functional-metadata/level-mode/level-mode.enum';
import { FunctionalMetadataService } from '../functional-metadata/functional-metadata.service';


@Injectable({
  providedIn: 'root'
})
export class FunctionalMetadataFormHandlerService {
  constructor(protected functionalMetadataService: FunctionalMetadataService) { }

  /**
   * Given a form gets the functional metadata values depending on the level
   *
   * @param form FormGroup
   * @param level LevelModeEnum
   * @returns any
   */
  getFormValuesByLevel(form: FormGroup, level: LevelModeEnum): any {
    const response = { };

    this.functionalMetadataService.getAttributesByLevel(level).filter(
      (attribute: Attribute) => attribute.isEditable() || (level === LevelModeEnum.title)
    ).forEach(
      (filteredAttribute: Attribute) => Object.assign(response, this.getValueByAttribute(filteredAttribute, form, level))
    );

    return response;
  }

  /**
   * Gets the attribute value according to the business rules
   *
   * @param attribute Attribute
   * @param form FormGroup
   * @param level LevelModeEnum
   * @returns any
   */
  protected getValueByAttribute(attribute: Attribute, form: FormGroup, level: LevelModeEnum): any {
    const attributeControl: AbstractControl = form.get(attribute.getName());
    const attributeValue: any = { };
    let hasOverride: boolean;

    if (!attributeControl) {
      return attributeValue;
    }

    if (attribute.hasSwitch()) {
      hasOverride = form.get(attribute.getName() + 'Override').value;

      if (hasOverride) {
        attributeValue[attribute.getName()] = attributeControl.value ? true : false;
      } else {
        attributeValue[attribute.getName()] = null;
      }
    } else {
      if (
        level === LevelModeEnum.title &&
        attribute.getName() === FunctionalMetadataEnum.excludeKidsMode &&
        attributeControl.value
      ) {
        attributeValue[attribute.getName()] = true;
      } else {
        attributeValue[attribute.getName()] = attributeControl.value ? attributeControl.value : null;
      }
    }

    return attributeValue;
  }
}
