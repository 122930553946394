export enum ActionTypeEnum {
  all = 'all',
  delete = 'delete',
  lock = 'lock',
  merge = 'merge',
  read = 'read',
  refresh = 'refresh',
  share = 'share',
  unlock = 'unlock',
  write = 'write'
}
