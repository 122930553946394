import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, AfterContentInit, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  differenceWith as _differenceWith,
  isArray as _isArray,
  isObject as _isObject,
  isString as _isString,
  reject as _reject,
  isUndefined as _isUndefined,
  concat as _concat
} from 'lodash';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-grouped-multiselect-field',
  templateUrl: 'bolt-grouped-multiselect-field.html',
  styleUrls: ['bolt-grouped-multiselect-field.scss']
})
export class BoltGroupedMultiselectFieldComponent extends BoltInputField implements OnChanges, OnInit, AfterContentInit {
  @Input() allValue: any;
  @Input() loading: boolean = false;
  @Input() options: SelectionItem[] = new Array();
  @Input() scrollHeight: string;
  @Output('changed') changeEvent: EventEmitter<any> = new EventEmitter();

  mappedOptions: any[] = new Array();
  selectedOptions: any[] = new Array();
  settings: any;
  value: number[] = new Array();

  // We need this hardcoded number to limit the items to show.
  readonly maxSelectedLabels: number = 999999;
  readonly SELECT_ALL_GROUP: string = 'selectAll';

  constructor(
    public ngControl: NgControl,
    public appConfigurationManager: AppConfigurationManager
  ) {
    super(ngControl);
  }

  ngOnInit() {
    this.setupSettings();
  }

  ngAfterContentInit() {
    this.setDefaultValues();
  }

  ngOnChanges(change: SimpleChanges) {
    if (_isObject(change.options) && _isArray(this.options)) {
      this.mapOptions();
    }
  }

  /**
   * Set the disabled state.
   *
   * @param val boolean
   * @returns void
   */
  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.setupSettings();
  }

  /**
   * Writes a value.
   *
   * @param value number[]
   * @returns void
   */
  writeValue(value: number[]): void {
    this.value = value;
    this.synchronizeSelections();
  }

  /**
   * Indicates if the given item is the last selection.
   *
   * @param item any
   * @returns boolean
   */
  isLastSelectedItem(item: any): boolean {
    const index: number = this.selectedOptions.findIndex((selected: any) => selected.label === item.label);
    return index === this.selectedOptions.length - 1;
  }

  /**
   * Emits any current change.
   *
   * @param event any
   * @returns void
   */
  emitChange($event: any): void {
    let mappedEvent: any;

    if (_isArray($event)) {
      mappedEvent = {
        isGroup: false,
        group: this.SELECT_ALL_GROUP,
        value: $event
      };
    } else {
      mappedEvent = {
        isGroup: $event.grpTitle ? $event.grpTitle : false,
        group: $event.group,
        value: $event.grpTitle ? $event.list : $event.value
      };
    }

    this.onModelChange(this.selectedOptions.map((elem: any) => elem.value));
    this.changeEvent.emit(mappedEvent);
  }

  /**
   * Ensures all item in first position.
   *
   * @returns void
   */
  protected ensureAllItemFirst(): void {
    const allItem: any = this.mappedOptions.find((option: any) => option.value === this.allValue);

    if (!_isUndefined(allItem)) {
      this.mappedOptions = _reject(this.mappedOptions, (item: any) => item.value === allItem.value);
      this.mappedOptions = _concat([allItem], this.mappedOptions);
    }
  }

  /**
   * Indicates if it has missing changes from the form model.
   *
   * @returns boolean
   */
  protected hasMissingFormModelChange(): boolean {
    const hasIt: boolean = this.selectedOptions.length === 0 &&
      _isArray(this.value) &&
      this.value.length > 0;

    return hasIt;
  }

  /**
   * Map the current options array.
   *
   * @returns void
   */
  protected mapOptions(): void {
    this.mappedOptions = this.options.map((option: SelectionItem) => option.getRawObject());
    this.ensureAllItemFirst();
    this.synchronizeSelections();
  }

  /**
   * Set the default value.
   *
   * @returns void
   */
  protected setDefaultValues(): void {
    if (this.hasMissingFormModelChange()) {
      this.setupSelectedOptions();
    }
  }

  /**
   * Set options with the selected values.
   *
   * @returns void
   */
  protected setupSelectedOptions(): void {
    const selectedOptions: any[] = [];

    this.value.forEach(
      (selectedValue: any) => {
        const option: any = this.mappedOptions.find((mappedOption: any) => mappedOption.value === selectedValue);

        if (_isObject(option)) {
          selectedOptions.push(option);
        }
      }
    );

    this.selectedOptions = selectedOptions;
  }

  /**
   * Set settings with the given values.
   *
   * @returns void
   */
  protected setupSettings(): void {
    if (_isString(this.scrollHeight) && this.scrollHeight.includes('px')) {
      this.scrollHeight = this.scrollHeight.slice(0, this.scrollHeight.length - 2);
    }

    this.settings = {
      singleSelection: false,
      text: this.placeHolder,
      badgeShowLimit: this.maxSelectedLabels,
      groupBy: 'group',
      labelKey: 'label',
      primaryKey: 'value',
      searchBy: ['label'],
      enableSearchFilter: true,
      enableFilterSelectAll: false,
      selectAllText: '',
      unSelectAllText: '',
      disabled: this.disabled,
      maxHeight: this.scrollHeight,
      searchPlaceholderText: '',
      classes: 'bolt-group-multiselect',
      position: 'bottom'
    };
  }

  /**
   * Synchronizes the selections with the current form value.
   *
   * @returns void
   */
  protected synchronizeSelections(): void {
    if (this.hasMissingFormModelChange()) {
      this.setupSelectedOptions();
    } else {
      const difference: any[] = _differenceWith(
        this.selectedOptions,
        this.value,
        (elemA: any, elemB: number) => elemA.value === elemB
      );

      if (difference.length > 0) {
        this.selectedOptions = _reject(
          this.selectedOptions,
          (selected: any) => difference.some((elem: any) => elem.value === selected.value)
        );
      }
    }
  }
}
