import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { isNull as _isNull } from 'lodash';

import { ProximityManager } from '../../services/proximity-manager/proximity-manager.service';


@Directive({ selector: 'p-dropdown[boltProximityFilter]' })
export class BoltProximityDropdownFilterDirective {
  @Input() key: string;

  constructor(
    protected el: ElementRef,
    protected proximityManager: ProximityManager
  ) { }

  @HostListener('input') onOpen(): void {
    const inputHtmlElement: HTMLInputElement = this.el.nativeElement.querySelector('.ui-dropdown-filter');
    this.proximityManager.addQuery(this.key, inputHtmlElement.value);
  }
}
