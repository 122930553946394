import { AccountInterface } from '../account/account.interface';
import { CountryInterface } from '../country/country.interface';
import { RatingSystemReasonInterface } from '../rating-system-reason/rating-system-reason.interface';


export enum RatingSystemType {
  OFFICIAL = <any>'OFFICIAL',
  CLIENT = <any>'CLIENT',
}

export interface RatingSystemClientInterface {
  account: AccountInterface;
  territory: CountryInterface;
}

export interface RatingSystemInterface {
  id: number;
  name: string;
  type: RatingSystemType;
  ratingSystemReasons: RatingSystemReasonInterface[];
  territories?: CountryInterface[];
  clients?: RatingSystemClientInterface[];
}

export class RatingSystem implements RatingSystemInterface {
  public ratingSystemReasons: RatingSystemReasonInterface[] = [];
  public territories: CountryInterface[] = [];
  public clients: RatingSystemClientInterface[] = [];

  constructor(
    public id: number,
    public name: string,
    public type: RatingSystemType
  ) { }

  setApplicableTerritories(territories: CountryInterface[]) {
    this.territories = territories;
    return this;
  }

  setApplicableClients(clients: RatingSystemClientInterface[]) {
    this.clients = clients;
    return this;
  }

  setRatingSystemReasons(ratingSystemReasons: RatingSystemReasonInterface[]) {
    this.ratingSystemReasons = ratingSystemReasons;
    return this;
  }

  toString() {
    return this.name;
  }
}
