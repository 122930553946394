import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { ProximityItem } from '@bolt/ui-shared/droplists';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-proximity-field',
  templateUrl: 'bolt-proximity-field.html',
  styleUrls: ['bolt-proximity-field.scss']
})
export class BoltProximityFieldComponent extends BoltInputField {
  @Input() key: string;
  @Input() loading: boolean = false;
  @Input() options: ProximityItem[];

  value: number[];

  constructor(
    public ngControl: NgControl,
    public appConfigurationManager: AppConfigurationManager
  ) {
    super(ngControl);
  }
}
