import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataShareModule } from '@bolt/ui-shared/data-share';
import { TooltipModule } from 'primeng';

import { BoltMoratoriumBannerComponent } from './components/bolt-moratorium-banner/bolt-moratorium-banner.component';
import { BoltMoratoriumLabelComponent } from './components/bolt-moratorium-label/bolt-moratorium-label.component';
import { BoltMoratoriumMarkComponent } from './components/bolt-moratorium-mark/bolt-moratorium-mark.component';


@NgModule({
  imports: [
    CommonModule,
    DataShareModule,
    TooltipModule
  ],
  declarations: [
    BoltMoratoriumBannerComponent,
    BoltMoratoriumLabelComponent,
    BoltMoratoriumMarkComponent
  ],
  exports: [
    BoltMoratoriumBannerComponent,
    BoltMoratoriumLabelComponent,
    BoltMoratoriumMarkComponent
  ],
  providers: [ ]
})
export class MoratoriumModule { }
