import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataTransformModule } from '@bolt/ui-shared/data-transform';
import { TooltipModule, OverlayPanelModule } from 'primeng';

import { BoltAutoFocusDirective } from './directives/bolt-auto-focus/bolt-auto-focus.directive';
import { BoltErrorMarkComponent } from './components/bolt-error-mark/bolt-error-mark.component';
import { BoltInputTrimDirective } from './directives/bolt-input-trim/bolt-input-trim.directive';
import { BoltLengthCounterComponent } from './components/bolt-length-counter/bolt-length-counter.component';
import { BoltLockingStatusIndicatorComponent } from './components/bolt-locking-status-indicator/bolt-locking-status-indicator.component';
import { BoltNoDataMarkComponent } from './components/bolt-no-data-mark/bolt-no-data-mark.component';
import { BoltNotApplicableMarkComponent } from './components/bolt-not-applicable-mark/bolt-not-applicable-mark.component';
import { BoltSpinnerMarkComponent } from './components/bolt-spinner-mark/bolt-spinner-mark.component';
import { BoltUnknownMarkComponent } from './components/bolt-unknown-mark/bolt-unknown-mark.component';
import { BoltTypeLabelComponent } from './components/bolt-type-label/bolt-type-label.component';
import { BoltOnOffSwitchComponent } from './components/bolt-on-off-switch/bolt-on-off-switch.component';
import { BoltCountdownButtonComponent } from './components/bolt-countdown-button/bolt-countdown-button.component';
import { BoltListAccordionComponent } from './components/bolt-list-accordion/bolt-list-accordion.component';
import { BoltNg2RtlDirective } from './directives/bolt-ng2-rtl/bolt-ng2-rtl.directive';
import { BoltTipPanelComponent } from './components/bolt-tip-panel/bolt-tip-panel.component';


@NgModule({
  imports: [
    AngularCommonModule,
    DataTransformModule,
    TooltipModule,
    OverlayPanelModule
  ],
  declarations: [
    BoltAutoFocusDirective,
    BoltErrorMarkComponent,
    BoltInputTrimDirective,
    BoltLengthCounterComponent,
    BoltLockingStatusIndicatorComponent,
    BoltNoDataMarkComponent,
    BoltNotApplicableMarkComponent,
    BoltSpinnerMarkComponent,
    BoltNg2RtlDirective,
    BoltUnknownMarkComponent,
    BoltTypeLabelComponent,
    BoltOnOffSwitchComponent,
    BoltCountdownButtonComponent,
    BoltListAccordionComponent,
    BoltTipPanelComponent
  ],
  exports: [
    BoltAutoFocusDirective,
    BoltErrorMarkComponent,
    BoltInputTrimDirective,
    BoltLengthCounterComponent,
    BoltLockingStatusIndicatorComponent,
    BoltNoDataMarkComponent,
    BoltNotApplicableMarkComponent,
    BoltNg2RtlDirective,
    BoltSpinnerMarkComponent,
    BoltUnknownMarkComponent,
    BoltTypeLabelComponent,
    BoltOnOffSwitchComponent,
    BoltCountdownButtonComponent,
    BoltListAccordionComponent,
    BoltTipPanelComponent
  ],
  providers: []
})
export class CommonModule { }
