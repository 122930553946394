import { cloneDeep as _cloneDeep } from 'lodash';
import { StormListInterface } from './storm-list.interface';
import { StormListType } from './storm-list-type.enum';


export interface StormListsInterface {
  lists: { [listType: string]: StormListInterface };
  addList(type: StormListType, list: StormListInterface);
  getList(type: StormListType): StormListInterface;
  hasList(type: StormListType): boolean;
  missingLists(): StormListType[];
}

export class StormLists implements StormListsInterface {

  constructor(
    public lists: { [listType: string]: StormListInterface} = { }
  ) { }

  addList(type: StormListType, list: StormListInterface) {
    this.lists[type] = list;
  }

  getList(type: StormListType): StormListInterface {
    return _cloneDeep(this.lists[type]);
  }

  hasList(type: StormListType): boolean {
    return !!this.lists[type];
  }

  missingLists() {
    const allLists = Object.keys(StormListType).map(key => StormListType[key]);

    return allLists.filter(
      list => !this.lists[list] || (this.lists[list].collection.length === 0)
    );
  }
}
