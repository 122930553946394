import { kebabCase as _kebabCase, isUndefined as _isUndefined, isString as _isString } from 'lodash';


export class AccordionItem {
  protected bulletClass: string | undefined;
  protected label: string;
  protected name: string;

  constructor(label: string, bulletClass?: string) {
    this.setLabel(label);
    this.setBulletClass(bulletClass);
    this.discoverName();
  }

  /**
   * Returns the bullet class.
   *
   * @return string | undefined
   */
  getBulletClass(): string | undefined {
    return this.bulletClass;
  }

  /**
   * Returns the label.
   *
   * @returns string
   */
  getLabel(): string {
    return this.label;
  }

  /**
   * Returns the name generated from the stored label.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Discover the name by using the stored label.
   *
   * @returns void
   */
  protected discoverName(): void {
    this.name = _kebabCase(this.label);
  }

  /**
   * Sets the bullet class.
   *
   * @params bulletClass string
   * @returns void
   */
  protected setBulletClass(bulletClass: string): void {
    const errorMessage: string = 'Accordion Item: Invalid bullet class given, expecting a non-empty string or an undefined value.';

    if (_isUndefined(bulletClass)) {
      this.bulletClass = undefined;
    } else if (_isString(bulletClass)) {
      const parsedBulletClass: string = bulletClass.trim();

      if (parsedBulletClass.length > 0) {
        this.bulletClass = parsedBulletClass;
      } else {
        throw new Error(errorMessage);
      }
    } else {
      throw new Error(errorMessage);
    }
  }

  /**
   * Sets the label.
   *
   * @params label string
   * @returns void
   */
  protected setLabel(label: string): void {
    const errorMessage: string = 'Accordion Item: Invalid label given, expecting a non-empty string.';

    if (_isString(label)) {
      const parsedLabel: string = label.trim();

      if (parsedLabel.length > 0) {
        this.label = parsedLabel;
      } else {
        throw new Error(errorMessage);
      }
    } else {
      throw new Error(errorMessage);
    }
  }
}
