import { DebugElement } from '@angular/core';
import { ComponentFixture } from '@angular/core/testing';
import { By } from '@angular/platform-browser';


/**
 * Returns the debug element in the given fixture, using the given CSS selector.
 *
 * @params fixture ComponentFixture<any>
 * @params selector string
 * @returns DebugElement
 */
export function findDebugElement(fixture: ComponentFixture<any>, selector: string): DebugElement {
 return fixture.debugElement.query(By.css(selector));
}

/**
 * Returns the debug elements in the given fixture, using the given CSS selector.
 *
 * @params fixture ComponentFixture<any>
 * @params selector string
 * @returns DebugElement
 */
export function findDebugElements(fixture: ComponentFixture<any>, selector: string): DebugElement[] {
 return fixture.debugElement.queryAll(By.css(selector));
}
