import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-error-mark',
  templateUrl: 'bolt-error-mark.html',
  styleUrls: ['bolt-error-mark.scss']
})
export class BoltErrorMarkComponent {
  @Input() reason: string;
  @Input() show: boolean;

  constructor() {
    this.reason = undefined;
    this.show = false;
  }
}
