import { includes as _includes } from 'lodash';

import { DirectionEnum } from './direction.enum';


export class Direction {
  protected _value: DirectionEnum;

  constructor(value: DirectionEnum) {
    const normalizedValue: string = String(value).trim().toLowerCase();

    if (_includes(DirectionEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new Error('Invalid value given for a direction.');
    }
  }

  get value(): DirectionEnum {
    return this._value;
  }

  /**
   * Indicates if it is ascendent "Asc".
   *
   * @returns boolean
   */
  isAsc(): boolean {
    return this.is(DirectionEnum.Asc);
  }

  /**
   * Indicates if it is descendent "Desc".
   *
   * @returns boolean
   */
  isDesc(): boolean {
    return this.is(DirectionEnum.Desc);
  }

  /**
   * Set the stored value to ascendent "Asc".
   *
   * @returns void
   */
  setAsc(): void {
    this._value = DirectionEnum.Asc;
  }

  /**
   * Set the stored value to descendent "Desc".
   *
   * @returns void
   */
  setDesc(): void {
    this._value = DirectionEnum.Desc;
  }

  /**
   * Toggles the stored value.
   *
   * @returns void
   */
  toggle(): void {
    if (this.isAsc()) {
      this.setDesc();
    } else {
      this.setAsc();
    }
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value DirectionEnum
   * @returns boolean
   */
  protected is(value: DirectionEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
