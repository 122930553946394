import { Injectable } from '@angular/core';


@Injectable()
export class WindowWrapperService {
  private window: Window;

  constructor() {
    this.window = window;
  }

  /**
   * Gets the document from window.
   *
   * @returns Document
   */
  getDocument(): Document {
    return this.window.document;
  }

  /**
   * Gets the location from window.
   *
   * @returns Location
   */
  getLocation(): Location {
    return this.window.location;
  }

  /**
   * Gets browser.
   *
   * @returns Navigator
   */
  getNavigator(): Navigator {
    return this.window.navigator;
  }

  /**
   * Gets window.
   *
   * @returns Window
   */
  getWindow(): Window {
    return this.window;
  }
}
