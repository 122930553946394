import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bolt-on-off-switch',
  templateUrl: 'bolt-on-off-switch.html',
  styleUrls: ['bolt-on-off-switch.scss'],
})
export class BoltOnOffSwitchComponent {
  @Input() disabled: boolean;
  @Input() size: number;
  @Input() target: any;
  @Output() onSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.disabled = false;
    this.size = 1;
    this.target = undefined;
  }

  /**
   * Toggles the current status and emits the notification.
   *
   * @returns void
   */
  toggle(): void {
    if (!this.disabled) {
      this.target.toggle();
      this.onSwitch.emit(this.target);
    }
  }
}
