import { sortBy as _sortBy, isEmpty as _isEmpty, isArray as _isArray } from 'lodash';
import { Account } from '../../models/account/account.model';
import { GenreTitleType, GenreType } from '../../models/genre/genre.model';
import { Country } from '../../models/country/country.model';
import { TerritoryAccountUnit } from '../../models/territory-account-unit/territory-account-unit.model';
import { SelectionItem } from '@bolt/ui-shared/droplists';

export abstract class GenreFilterHelper {

  /**
   * Return the filtered genre list
   *
   * @param genreSelectionItems SelectionItem[]
   * @param genreTitleType GenreTitleType
   * @param accountId number[]
   * @param territoryId number[]
   * @returns SelectionItem[]
   */
  static filterGenres(
    genreSelectionItems: SelectionItem[],
    genreTitleType: GenreTitleType,
    accountId?: number[],
    territoryId?: number[]
  ): SelectionItem[] {
    const hasOneAccountSelected: boolean = accountId && accountId.length === 1;
    const hasOneTerritorySelected: boolean = territoryId && territoryId.length === 1;

    const accountIsAll: boolean = hasOneAccountSelected && Account.isAll(accountId[0]);
    const territoryIsAll: boolean = hasOneTerritorySelected && Country.isAll(territoryId[0]);

    // Filter by ACCOUNT_TERRITORY
    if (
      (hasOneAccountSelected && !accountIsAll) &&
      (hasOneTerritorySelected && !territoryIsAll)
    ) {
      return this.getFilteredGenresByAccountTerritory(genreSelectionItems, accountId[0], territoryId[0]);

    } else if (
        // Filter by ACCOUNT
        (hasOneAccountSelected && !accountIsAll) &&
        (!hasOneTerritorySelected || territoryIsAll)
      ) {
        return this.getFilteredGenresByAccount(genreSelectionItems, genreTitleType, accountId[0]);

    } else {
      // Filter by TYPE
      return this.getFilteredGenresByType(genreSelectionItems, genreTitleType);
    }
  }

  /**
   * Return the genre list Filtered by the genre type of the title and by officials only.
   *
   * @param genreSelectionItems SelectionItem[]
   * @param genreTitleType GenreTitleType
   * @returns SelectionItem[]
   */
  protected static getFilteredGenresByType(
    genreSelectionItems: SelectionItem[],
    genreTitleType: GenreTitleType
  ): SelectionItem[] {
    const genres = genreSelectionItems.filter(
      (item: SelectionItem) => {
        const byTitleType = item.source.titleType === genreTitleType ||
                            item.source.titleType === GenreTitleType.ANY;
        const byNone = item.source.genreType === GenreType.NONE;

        return byTitleType && byNone;
      });

    return genres;
  }

  /**
   * Return the genre list Filtered by the genre type of the title and by its list of accounts.
   *
   * @param genreSelectionItems SelectionItem[]
   * @param genreTitleType GenreType
   * @param accountId number
   * @returns Array<SelectionItem>
   */
   protected static getFilteredGenresByAccount(
     genreSelectionItems: SelectionItem[],
     genreTitleType: GenreTitleType,
     accountId: number
  ): SelectionItem[] {
    const accountGenres = genreSelectionItems.filter(
      (item: SelectionItem) => {
        const isTitleType: boolean = item.source.titleType === genreTitleType ||
                                     item.source.titleType === GenreTitleType.ANY;
        const isAccountType: boolean = item.source.genreType === GenreType.ACCOUNT;
        const isAccount: boolean = item.source.accounts.some(
          (account: Account) => account.id === accountId
        );

        return isTitleType && isAccountType && isAccount;
      });

    return _sortBy(accountGenres, 'label');
  }

  /**
   * Return the genre list Filtered by the genre type of the title and by its list of accounts and territories.
   *
   * @param genreSelectionItems SelectionItem[]
   * @param accountId number
   * @param territoryId number
   * @returns Array<SelectionItem>
   */
   protected static getFilteredGenresByAccountTerritory(
    genreSelectionItems: SelectionItem[],
    accountId: number,
    territoryId: number
  ): SelectionItem[] {
    const accountTerritoryGenres = genreSelectionItems.filter(
      (item: SelectionItem) => {
        const isAccountTerritoryType: boolean = item.source.genreType === GenreType.ACCOUNT_TERRITORY;
        const isAccountTerritory: boolean =
          item.source.territoryAccountUnits.some((unit: TerritoryAccountUnit) =>
            unit.account.id === accountId &&
            unit.territory.id === territoryId
          );

        return isAccountTerritoryType && isAccountTerritory;
      });

    return _sortBy(accountTerritoryGenres, 'label');
  }
}
