import { Component, Input, OnInit } from '@angular/core';

import { Rating, RatingSystemReason, RatingSystemInterface, AuthoritativeRatingTypeEnum } from '@bolt/ui-shared/master-data';
import { isNumber as _isNumber, isFunction as _isFunction } from 'lodash';


@Component({
  selector: 'bolt-rating',
  templateUrl: 'bolt-rating.component.html',
  styleUrls: ['bolt-rating.scss']
})
export class BoltRatingComponent implements OnInit {
  @Input() homeEntertainmentRatings: Rating[] = [];
  @Input() theatricalRatings: Rating[] = [];
  @Input() homeEntertainmentRatingReasonSystem: RatingSystemReason[] = [];
  @Input() theatricalRatingReasonSystem: RatingSystemReason[] = [];
  @Input() homeEntertainmentRatingReason: string;
  @Input() theatricalRatingReason: string;
  @Input() isRtl: boolean = false;
  @Input() titleType: string;

  loaded = false;
  ratings: any[];

  constructor() { }

  ngOnInit(): void {
    this.setRatings();

    this.loaded = true;
  }

  setRatings(): void {
    this.homeEntertainmentRatings = this.sortRatings(this.homeEntertainmentRatings);
    this.theatricalRatings = this.sortRatings(this.theatricalRatings);

    this.ratings = [
      ...this.getRatingsData(
        this.homeEntertainmentRatings,
        this.homeEntertainmentRatingReasonSystem,
        this.homeEntertainmentRatingReason,
        AuthoritativeRatingTypeEnum.homeEdition
      ),
      ...this.getRatingsData(
        this.theatricalRatings,
        this.theatricalRatingReasonSystem,
        this.theatricalRatingReason,
        AuthoritativeRatingTypeEnum.theatrical
      )
    ];
  }

  protected sortRatings(ratings: Rating[]): Rating[] {
    const sortedRatings = ratings?.sort(
      (a: Rating, b: Rating) => {
        if (a.ratingSystem.name > b.ratingSystem.name) {
          return +1;
        } else if (a.ratingSystem.name < b.ratingSystem.name) {
          return -1;
        } else {
          if (a.name > b.name) {
            return +1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    );

    return sortedRatings;
  }

  protected getRatingsData(
    ratings: Rating[],
    ratingSystemReasons: RatingSystemReason[],
    ratingReason: string,
    ratingType: AuthoritativeRatingTypeEnum
  ): any {
    const ratingSystems = new Map();
    let mappedRatings = [];

    ratings?.forEach(
      (rating: Rating) => {
        const systems = ratingSystems.get((rating.ratingSystemId as any).id) ? ratingSystems.get((rating.ratingSystemId as any).id) : [];
        const mappedRating: any = { };

        mappedRating.systemVisible = systems.length === 0;
        mappedRating.rating = rating;
        mappedRating.sourceVisible = mappedRatings.length === 0;

        mappedRating.ratingReason = ratingReason;
        mappedRating.totalRatings = ratings.length;
        mappedRating.ratingType = ratingType;
        mappedRating.typeClass = this.getIcon(mappedRating, this.titleType);
        mappedRating.typeName = this.getTypeName(mappedRating, this.titleType);

        systems.push(mappedRating);
        ratingSystems.set((rating.ratingSystemId as any).id, systems);
        mappedRatings.push(mappedRating);
      }
    );

    mappedRatings = mappedRatings?.map(
      (mappedRating) => {
        if (mappedRating.systemVisible) {
          mappedRating.systemSize = this.getRatingSystemSize(mappedRating.rating.ratingSystemId.id, ratingSystems);
          mappedRating.systemsText = this.getRatingSystemsText(ratingSystemReasons, mappedRating.rating.ratingSystemId.id);
        }

        return mappedRating;
      }
    );

    return mappedRatings;
  }

  protected getSystemsById(systems: RatingSystemReason[], id: number): RatingSystemReason[] {
    const filteredSystems = systems?.filter(
      (ratingSystemReason: RatingSystemReason) => ratingSystemReason.ratingSystems.some(
        (ratingSystem: RatingSystemInterface) => ratingSystem.id === id
      )
    );

    return filteredSystems;
  }

  protected getIcon(mappedRating: any, titleType: string): string {
    let ratingClass: string;

    switch (mappedRating.ratingType) {
      case AuthoritativeRatingTypeEnum.theatrical:
        if (titleType === 'feature') {
          ratingClass = 'fa fa-film';
        } else {
          ratingClass = 'fa fa-tv';
        }
        break;
      default:
        ratingClass = 'fa fa-home';
        break;
    }

    return ratingClass;
  }

  protected getTypeName(mappedRating: any, titleType: string): string {
    let ratingTypeName: string;

    switch (mappedRating.ratingType) {
      case AuthoritativeRatingTypeEnum.theatrical:
        if (titleType === 'feature') {
          ratingTypeName = 'Theatrical / TV';
        } else {
          ratingTypeName = 'TV';
        }
        break;
      default:
        ratingTypeName = 'Home Entertainment';
        break;
    }

    return ratingTypeName;
  }

  protected getRatingSystemSize(ratingSystemId: number, ratingSystems: any): number {
    const size = ratingSystems?.get(ratingSystemId)?.length;
    return size;
  }

  protected getRatingSystemsText(ratingSystems: any, id: number): string {
    const text = this.getSystemsById(ratingSystems, id)?.map(
      ratingSystem => ratingSystem.name
    ).join(', ');

    return text;
  }
}
