import { Injectable } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { get as _get, set as _set, merge as _merge, isUndefined as _isUndefined } from 'lodash';

import { ConfigurationTypeEnum } from '../models/configuration/type.enum';
import { AppConfigurationManager } from '../services/app-configuration/app-configuration.manager';


@Injectable()
export class AppConfigProvider {
  appConfig: object;
  valueTypes: typeof ConfigurationTypeEnum = ConfigurationTypeEnum;
  version: any;
  protected appConfigurationManager: AppConfigurationManager;

  constructor() {
    this.appConfig = { };
    this.version = { };
  }

  loadConfig(appConfigurationManager: AppConfigurationManager, globalConfig: object, environmentConfig: object, version: object) {
    this.appConfigurationManager = appConfigurationManager;
    this.setConfig(globalConfig, environmentConfig);
    this.setVersionInfo(version);
  }

  /**
   * Set the Application configuration.
   * Will merge global, environment and local configurations.
   *
   * @returns void
   */
  setConfig(globalConfig: object, environmentConfig: object): void {
    try {
      this.appConfig = _merge(
        new Object(),
        globalConfig,
        environmentConfig
      );

      this.setLocalConfig();
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Set the version info.
   *
   * @param version object
   * @returns void
   */
  setVersionInfo(version: object): void {
    this.version = version;
  }

  /**
   * Get the version info.
   *
   * @returns object
   */
  getVersionInfo(): object {
    return this.version;
  }

  /**
   * Gets the value at path of the app configuration object.
   * If the resolved value is undefined the defaultValue is used in its place.
   *
   * @param key string
   * @param defaultValue any
   * @param cast ConfigurationTypeEnum
   * @throws HttpError
   * @returns any
   */
  get(
    key: string,
    defaultValue?: any,
    cast: ConfigurationTypeEnum = ConfigurationTypeEnum.string
  ): any {
    let value = _get(this.appConfig, key);

    if (_isUndefined(value)) {
      try {
        value = this.appConfigurationManager.getConfiguration(key).value;
      } catch (error) {
        if (_isUndefined(defaultValue)) {
          throw new HttpError(`Failed trying to get ${key} configuration: ${error}`);
        } else {
          return defaultValue;
        }
      }
    } else {
      if (cast === ConfigurationTypeEnum.boolean) {
        value = !!parseInt(value, 10);
      } else if (cast === ConfigurationTypeEnum.number) {
        value = parseFloat(value);
      }
    }

    return value;
  }

  /**
   * Stores the local configuration.
   *
   * @param config object
   * @returns void
   */
  storeLocalConfig(config: object, globalConfig: object, environmentConfig: object): void {
    localStorage.setItem(
      this.get('application.localConfig.storageKey', 'storm-local-configuration'),
      JSON.stringify(config)
    );

    this.setLocalConfig();
  }

  /**
   * Reset the Application Configuration to its factory values.
   *
   * @returns void
   */
  resetConfig(globalConfig: object, environmentConfig: object): void {
    localStorage.removeItem(
      this.get('application.localConfig.storageKey', 'storm-local-configuration')
    );

    this.setLocalConfig();
  }

  /**
   * Sets the Local Configuration values.
   *
   * @returns void
   */
  setLocalConfig(): void {
    const localConfig = JSON.parse(
      localStorage.getItem(
        this.get('application.localConfig.storageKey', 'storm-local-configuration')
      )
    );

    if (localConfig !== null) {
      Object.keys(localConfig).forEach(k => {
        _set(this.appConfig, k, localConfig[k]);
      });
    }
  }
}
