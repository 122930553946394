export class AppRoutesServiceMock {
  protected storage: Map<string, string>;

  constructor() {
    this.storage = new Map();
  }

  get length(): number {
    return this.storage.size;
  }

  /**
   * Indicates if there is no registered data.
   *
   * @returns boolean
   */
  isEmpty(): boolean {
    const isIt: boolean = this.length === 0;
    return isIt;
  }

  /**
   * Returns the mocked value of the registered key.
   *
   * @param key string
   * @returns any
   */
  get(key: string): any {
    if (this.storage.has(key)) {
      return this.storage.get(key);
    } else {
      throw new Error(`No registered entry for "${key}" was found in AppRoutesServiceMock.`);
    }
  }

  /**
   * Registries key-value pair to be used them when `get()` is called.
   *
   * @param key string
   * @param value any
   * @return void
   */
  registry(key: string, value: any): void {
    this.storage.set(key, value);
  }
}
