import { AspectRatioInterface } from './aspect-ratio.interface';

export class AspectRatio implements AspectRatioInterface {

  constructor(
    public id: number,
    public name: string
  ) { }

  toString() {
    return this.name;
  }
}
