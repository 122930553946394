import { AccountInterface } from '../account/account.interface';
import { CountryInterface } from '../country/country.interface';
import { TerritoryAccountUnitInterface } from './territory-account-unit.interface';

export class TerritoryAccountUnit implements TerritoryAccountUnitInterface {

  public account: AccountInterface;
  public territory: CountryInterface;

  constructor(
    public accountValue: AccountInterface,
    public territoryValue: CountryInterface,
  ) {
    this.account = accountValue;
    this.territory = territoryValue;
  }

  setApplicableAccount(account: AccountInterface) {
    this.account = account;
    return this;
  }

  setApplicableTerritory(territory: CountryInterface) {
    this.territory = territory;
    return this;
  }
}
