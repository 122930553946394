export class EntryMock {
  protected _responseArguments: object;
  protected _url: string;

  constructor(url: string, responseArguments: object) {
    this.initialize(url, responseArguments);
  }

  get responseArguments(): object {
    return this._responseArguments;
  }

  get url(): string {
    return this._url;
  }

  /**
   * Initializes the instance.
   *
   * @param url string
   * @param responseArguments object
   * @return void
   */
  protected initialize(url: string, responseArguments: object): void {
    this._responseArguments = responseArguments;
    this._url = url;
  }
}
