import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { isNull as _isNull } from 'lodash';

@Directive({ selector: 'angular2-multiselect[boltEllipsis]' })
export class BoltGroupedMultiselectEllipsisDirective implements AfterViewInit {
  protected readonly overflowFix: number = 9;

  constructor(protected elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.setEllipsis();
  }

  @HostListener('onSelect')
  @HostListener('onDeSelect')
  @HostListener('onSelectAll')
  @HostListener('onDeSelectAll')
  @HostListener('onGroupSelect')
  @HostListener('onGroupDeSelect')
  onChanged(): void {
    this.setEllipsis();
  }

  /**
   * Set the ellipsis.
   *
   * @returns void
   */
  protected setEllipsis(): void {
    const callback: CallableFunction = () => {
      const elem: HTMLInputElement = this.elementRef.nativeElement.querySelector('.c-list');

      if (elem) {
        const clearAllElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('.clear-all');
        const ellipsisElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('#ellipsis');
        const isOverflow: boolean = elem.offsetWidth + this.overflowFix < elem.scrollWidth;

        if (isOverflow) {
          if (_isNull(ellipsisElement)) {
            clearAllElement.insertAdjacentHTML('afterbegin', '<label id="ellipsis">...</label>');
          }
        } else {
          if (ellipsisElement) {
            ellipsisElement.remove();
          }
        }
      }
    };

    setTimeout(callback, 0);
  }
}
