import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';


@Component({
  selector: 'bolt-dropdown-field',
  templateUrl: 'bolt-dropdown-field.html',
  styleUrls: ['bolt-dropdown-field.scss']
})
export class BoltDropdownFieldComponent extends BoltInputField {
  @Input() loading: boolean = false;
  @Input() options: SelectionItem[];
  @Input() placeHolder: string;

  value: number;

  constructor(
    public ngControl: NgControl,
    public appConfigurationManager: AppConfigurationManager
  ) {
    super(ngControl);
  }
}
