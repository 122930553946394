import * as moment from 'moment';
import { isObject as _isObject, isString as _isString, includes as _includes } from 'lodash';

import { MappableAttributeEnum } from './mappable-attribute.enum';

export class TitleEditHistory {
  protected _id: number;
  protected _property: string;
  protected _originalValue: any;
  protected _newValue: any;
  protected _user: string;
  protected _date: moment.Moment;
  protected _isMappable: boolean;
  protected _order: string;

  constructor(data: any) {
    if (_isObject(data as any)) {
      this._id = data.id ? data.id : data.entityId;
      this._property = data.property;
      this._originalValue = data.fromValue;
      this._newValue = data.toValue;
      this._user = data.createdBy;
      this._isMappable = _includes(MappableAttributeEnum, data.property);

      if (_isObject(data.created)) {
        this._date = data.created;
      } else if (_isString(data.created)) {
        this._date = moment(data.created);
      } else {
        this._date = undefined;
      }
    } else {
      throw new Error('Title Edit History: Invalid data given for instance creation');
    }
  }

  get id(): number {
    return this._id;
  }

  get property(): string {
    return this._property;
  }

  get originalValue(): any {
    return this._originalValue;
  }

  get newValue(): any {
    return this._newValue;
  }

  get user(): string {
    return this._user;
  }

  get date(): moment.Moment {
    return this._date;
  }

  get isMappable(): boolean {
    return this._isMappable;
  }

  get order(): string {
    return this._order;
  }

  set order(order: string) {
    this._order = order;
  }
}
