import { Injectable } from '@angular/core';

import { EnvironmentService } from '@bolt/ui-shared/common';

import { TypeEnum } from '../../../models/list/type.enum';
import { MasterDataCachedItem } from '../model/cached-item.model';
import { StormListType } from '../../../models/storm-list/storm-list-type.enum';

@Injectable()
export class MasterDataCacheService {
  private readonly itemMaxLife: number = 60;
  private readonly itemSuffix: string = 'MasterDataCache';
  private readonly debugColors: { [key: string]: string } = {
    main: 'background: #a8dadc; color: #1d3557; padding: 2px',
    secondary: 'background: #1d3557; color: white; padding: 2px'
  };

  constructor(private environmentService: EnvironmentService) { }

  /**
   * Reads a cached list from the storage and returns its value. If a value
   * is found but is not valid it'll get deleted
   *
   * @todo remove StormListType when the full move to MasterData is achieved
   * @param list StormListType | TypeEnum
   * @returns any[] | null
   */
  read(list: StormListType | TypeEnum): any[] | null {
    const key = this.buildKey(list);
    const item = localStorage.getItem(key);

    if (item) {
      const rawItem = JSON.parse(item);
      const storedItem: MasterDataCachedItem = new MasterDataCachedItem(
        rawItem.values, new Date(rawItem.createDate), this.itemMaxLife
      );

      if (storedItem.hasExpired()) {
        this.log(`Expired cache for ${list} found, removing from LocalStorage`);
        localStorage.removeItem(key);
      } else {
        this.log(`Cache for ${list} found, valid for ${storedItem.getRemainingMinutes()} minutes`);
      }

      return storedItem.getCached();
    }

    this.log(`No cache for ${list} found in LocalStorage`);

    return null;
  }

  /**
   * Clears all the stored caches
   *
   * @returns void
   */
  clear(): void {
    this.log(`Clearing all stored items from LocalStorage`);
    for (const item in localStorage) {
      if (localStorage.hasOwnProperty(item) && item.includes(this.itemSuffix)) {
        localStorage.removeItem(item);
      }
    }
  }

  /**
   * Clears the specified list's cache
   *
   * @todo remove StormListType when the full move to MasterData is achieved
   * @param list StormListType | TypeEnum
   * @returns void
   */
  remove(list: StormListType | TypeEnum): void {
    this.log(`Removing ${list} from LocalStorage`);
    localStorage.removeItem(this.buildKey(list));
  }

  /**
   * Writes a serialized MasterDataCacheItem into LocalStorage
   *
   * @todo remove StormListType when the full move to MasterData is achieved
   * @param list StormListType | TypeEnum
   * @param data any[]
   * @throws Error
   * @returns void
   */
  write(list: StormListType | TypeEnum, data: any[]): void {
    const item = new MasterDataCachedItem(data, new Date(), this.itemMaxLife);

    try {
      localStorage.setItem(this.buildKey(list), item.stringify());
    } catch (storageError) {
      this.log(`Error while writing into LocalStorage: ${storageError}`);
      throw new Error(storageError);
    } finally {
      this.log(`Wrote data for ${list} into LocalStorage`);
    }
  }

  /**
   * Builds a string to be used as a key
   *
   * @todo remove StormListType when the full move to MasterData is achieved
   * @param list StormListType | TypeEnum
   * @returns string
   */
  private buildKey(list: StormListType | TypeEnum): string {
    return `${list.toString()}${this.itemSuffix}`;
  }

  /**
   * Logs debug info
   *
   * @param text string
   * @returns void
   */
  private log(text: string): void {
    const canLog = !this.environmentService.isProd();

    if (canLog) {
      const logged = `%cCacheService:%c${text}`;

      console.debug(logged, this.debugColors.main, this.debugColors.secondary);
    }
  }
}
