import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {
  transform(value: string | number, defaultValue: string | number): string | number {
    const output: string | number = value ? value : defaultValue;
    return output;
  }
}
