import { EntryMock } from './entry/entry.mock';


export class AppConfigProviderMock {
  protected keys: EntryMock[];

  constructor() {
    this.initialize();
  }

  /**
   * Mocks the getting APP config call.
   *
   * @param key string
   * @param defaultValue any
   * @param cast any
   * @returns any
   */
  get(key: string, defaultValue?: any, cast?: any): any {
    return this.retrieveEntry(key).value;
  }

  /**
   * Registries response arguments for a key, to be used them when get() is called.
   *
   * @param key string
   * @param value any
   * @return void
   */
  registryEntry(key: string, value: any): void {
    const entry: EntryMock = new EntryMock(key, value);
    this.keys.push(entry);
  }

  /**
   * Retrieves the entry with the given key.
   *
   * @param key string
   * @return EntryMock
   */
  protected retrieveEntry(key: string): EntryMock {
    const entry: EntryMock = this.keys.find(
      (candidate: EntryMock) => {
        const found = (candidate.key === key);
        return found;
      }
    );

    if (entry) {
      return entry;
    } else {
      throw new Error(`No registered entry for APP config key "${key}"`);
    }
  }

  protected initialize(): void {
    this.keys = new Array();
  }
}

