export class MasterDataCachedItem {
  private values: any[];
  private maxLife: number;
  private createDate: Date;

  constructor(values: any[], createDate: Date, maxLife: number) {
    if (values && createDate && maxLife) {
      this.createDate = createDate;
      this.values = values;
      this.maxLife = maxLife;
    } else {
      throw new Error('MasterDataCachedItem:: Missing constructor parameters');
    }
  }

  /**
   * Returns the stored value of the Cached Item
   *
   * @returns any[]
   */
  getValues(): any[] {
    return this.values;
  }

  /**
   * Returns the cached value if possible
   *
   * @returns any[] | null
   */
  getCached(): any[] | null {
    return this.hasExpired() ? null : this.values;
  }

  /**
   * Gets the remaining minutes until the cache is invalid
   *
   * @returns number
   */
  getRemainingMinutes(): number {
    const diffInMinutes = this.getMinutesSinceCreation();

    return diffInMinutes > this.maxLife ? 0 : Math.ceil(this.maxLife - diffInMinutes);
  }

  /**
   * Indicates if the cached item has expired
   *
   * @returns boolean
   */
  hasExpired(): boolean {
    const expired = this.getMinutesSinceCreation() > this.maxLife;

    return expired;
  }

  /**
   * Returns an stringified representation of both the values and the creation date
   *
   * @returns string
   */
  stringify(): string {
    return JSON.stringify({
      createDate: this.createDate,
      values: this.values
    });
  }

  /**
   * Calculates the minutes that have passed since the cache's creation
   *
   * @returns number
   */
  private getMinutesSinceCreation(): number {
    const now = new Date();
    const diffInMs = Math.abs(now.getTime() - this.createDate.getTime());
    const diffInMinutes = Math.floor(diffInMs / 1000) / 60;

    return diffInMinutes;
  }
}
