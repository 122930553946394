import { Injectable } from '@angular/core';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';


@Injectable({
  providedIn: 'root',
})
export class ComputedMetadataHandlerService {
  constructor() { }

  /**
   * Returns the computed-from message for the locale where the value comes for the given field in metadata.
   *
   * @param metadata any
   * @param field string
   * @returns string
   */
  getComputedMessage(metadata: any, field: string): string {
    const locale: string = this.getComputedLocale(metadata, field);
    const message: string = this.isInheritedAttribute(metadata, field) && !_isUndefined(metadata[field]) ? `Computed from ${locale}` : '';

    return message;
  }

  /**
   * Indicates if the given field is an inherited attribute by checking if its locale is different from the locale in the given metadata.
   *
   * @param metadata any
   * @param field string
   * @returns boolean
   */
  isInheritedAttribute(metadata: any, field: string): boolean {
    const isIt: boolean = this.getComputedLocale(metadata, field) !== metadata.locale;
    return isIt;
  }

  /**
   * Returns the locale where the value comes for the given field in metadata, setting `*_*_*_*` as default.
   *
   * @param metadata any
   * @param field string
   * @returns string
   */
  protected getComputedLocale(metadata: any, field: string): string {
    const locale: string = metadata.localizedFields[field] || '*_*_*_*';
    return locale;
  }
}
