export class DubbingStudio {
  protected _id: number;
  protected _name: string;

  constructor(data: any) {
    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    this._name = (
      (data && data.hasOwnProperty('name'))
        ? data.name
        : undefined
    );
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object
   */
  public asEndpointData(): object {
    const data: object = {
      dubbingStudioId: this.id
    };

    return data;
  }
}
