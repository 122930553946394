import { isObject as _isObject, isString as _isString } from 'lodash';


export abstract class StringHelper {
  protected static readonly specialCharactersMap: Map<string, string> = new Map([
    ['a', 'á|à|ä|â'],
    ['e', 'é|è|ë|ê'],
    ['i', 'í|ì|ï|î'],
    ['o', 'ó|ò|ö|ô'],
    ['u', 'ú|ù|ü|û'],
    ['n', 'ñ|ń|ǹ'],
    ['A', 'Á|À|Ä|Â'],
    ['E', 'É|È|Ë|Ê'],
    ['I', 'Í|Ì|Ï|Î'],
    ['O', 'Ó|Ò|Ö|Ô'],
    ['U', 'Ú|Ù|Ü|Û'],
    ['N', 'Ñ|Ń|Ǹ']
  ]);

  /**
   * Checks if a given value is a string with text
   *
   * @param value any
   * @returns boolean
   */
  static isNonEmptyText(value: any): boolean {
    const isValid: boolean = _isString(value) && value.trim().length > 0;

    return isValid;
  }

  /**
   * Indicates if the given value is a URL.
   *
   * @param value string
   * @returns boolean
   */
  static isUrl(value: string): boolean {
    try {
      const url: URL = new URL(value);
      return _isObject(url);
    } catch (error) {
      return false;
    }
  }

  /**
   * Returns a new string that is URL safe.
   *
   * @param value string
   * @throws ErrorHelper
   * @returns string
   */
  static toUrlSafeParam(value: string): string {
    if (_isString(value)) {
      let output: string = value.trim().toLocaleLowerCase();

      if (output.length > 0) {
        output = this.replaceSpecialCharacters(output)
          .replace(/[^a-z0-9\-]/g, '-')
          .replace(/\-(\-+)/g, '-')
          .replace(/^\-/, '')
          .replace(/\-$/, '');

        if (output.length > 0) {
          return output;
        }
      }
    }

    throw new Error(`Invalid value given for converting to URL safe: ${value}`);
  }

  /**
   * Returns an array of strings based on words.
   *
   * @param value string
   * @throws ErrorHelper if the given value it's not a string.
   * @returns string[]
   */
  static toWords(value: string): string[] {
    if (_isString(value)) {
      const output: string[] = value.trim().replace(/\s(\s)+/, ' ').split(' ');

      return output;
    }

    throw new Error(`Invalid value given for transforming the string into words: ${value}`);
  }

  /**
   * Replaces the special characters using the map.
   *
   * @param value string
   * @returns string
   */
  protected static replaceSpecialCharacters(value: string): string {
    let output: string = value;

    this.specialCharactersMap.forEach(
      (pattern: string, character: string) => {
        output = output.replace(new RegExp(pattern, 'g'), character);
      }
    );

    return output;
  }
}
