export enum StormListType {
  account = <any>'account',
  affix = <any>'affix',
  aspectRatio = <any>'aspectRatio',
  company = <any>'company',
  dubbingStudios = <any>'dubbingStudios',
  functionalMetadata = <any>'functionalMetadata',
  genre = <any>'genre',
  language = <any>'language',
  primaryProductAssociation = <any>'primaryProductAssociation',
  productType = <any>'productType',
  rating = <any>'rating',
  ratingSystem = <any>'ratingSystem',
  ratingSystemReason = <any>'ratingSystemReason',
  secondaryProductAssociation = <any>'secondaryProductAssociation',
  territory = <any>'territory',
  xsltProperty = <any>'xsltProperty'
}
