import { Input } from '@angular/core';


export abstract class BoltMoratoriumComponent {
  @Input() show: boolean;

  readonly email: string = 'corp.brand.mgmt@disney.com';
  readonly message: string = 'Title is in Moratorium. Please contact Corporate Brand Management team for more details:';

  constructor() {
    this.show = false;
  }
}
