import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng';

import { BoltProximityDropdownComponent } from './components/bolt-proximity-dropdown/bolt-proximity-dropdown.component';
import { BoltProximityDropdownFilterDirective } from './directives/bolt-proximity-dropdown-filter/bolt-proximity-dropdown-filter.directive';
import { ProximityManager } from './services/proximity-manager/proximity-manager.service';


@NgModule({
  imports: [
    FormsModule,
    DropdownModule
  ],
  declarations: [
    BoltProximityDropdownComponent,
    BoltProximityDropdownFilterDirective
  ],
  exports: [
    BoltProximityDropdownComponent
  ],
  providers: [
    ProximityManager
  ]
})
export class DroplistsModule { }
