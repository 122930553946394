import { Message } from 'primeng';
import { SeverityEnum } from '@bolt/ui-shared/common';


export class Notification implements Message {
  protected _detail: string;
  protected _key: string;
  protected _life: number;
  protected _severity: SeverityEnum;
  protected _summary: string;

  constructor(severity: SeverityEnum, summary: string, detail?: string, key?: string, life?: number) {
    this._detail = detail;
    this._key = key;
    this._life = life;
    this._severity = severity;
    this._summary = summary;
  }

  get detail(): string {
    return this._detail;
  }

  get key(): string {
    return this._key;
  }

  get life(): number {
    return this._life;
  }

  get severity(): string {
    return this._severity.toString();
  }

  get summary(): string {
    return this._summary;
  }

  /**
   * Indicates if it is ERROR.
   *
   * @returns boolean
   */
  isError(): boolean {
    const isIt: boolean = (this.severity === SeverityEnum.ERROR.toString());
    return isIt;
  }

  /**
   * Indicates if it is INFO.
   *
   * @returns boolean
   */
  isInfo(): boolean {
    const isIt: boolean = (this.severity === SeverityEnum.INFO.toString());
    return isIt;
  }

  /**
   * Indicates if it is SUCCESS.
   *
   * @returns boolean
   */
  isSuccess(): boolean {
    const isIt: boolean = (this.severity === SeverityEnum.SUCCESS.toString());
    return isIt;
  }

  /**
   * Indicates if it is WARN.
   *
   * @returns boolean
   */
  isWarn(): boolean {
    const isIt: boolean = (this.severity === SeverityEnum.WARN.toString());
    return isIt;
  }
}
