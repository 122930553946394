import { AffixLocalizationInterface } from './affix-localization.interface';

export class AffixLocalization implements AffixLocalizationInterface {
  constructor(
    public id: number,
    public locale: string,
    public value: string
  ) { }

  toString() {
    return this.value;
  }
}
