import { cloneDeep as _cloneDeep, isArray as _isArray } from 'lodash';


export abstract class MetadataSanitizer {
  /**
   * Returns a sanitized object with null values for the given fields of the given data if they were empty arrays.
   *
   * @param data any
   * @param targetFields string[]
   * @returns any
   */
  static sanitizeEmptyLists(data: any, targetFields: string[]): any {
    if (_isArray(targetFields) && (targetFields.length === 0)) {
      throw Error('Expects a non empty array with fields to be sanitized.');
    }

    const sanitizedObject  = _cloneDeep(data);

    targetFields.forEach(
      (entry: any) => {
        if (_isArray(data[entry]) && (data[entry].length === 0)) {
          sanitizedObject[entry] = null;
        }
      }
    );

    return sanitizedObject;
  }
}
