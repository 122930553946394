import { NgModule } from '@angular/core';

import { AuthModule } from '@bolt/ui-shared/auth';
import { CommonModule } from '@bolt/ui-shared/common';


@NgModule({
  imports: [
    CommonModule,
    AuthModule
  ],
  declarations: [ ],
  exports: [ ],
  providers: [ ]
})
export class MasterDataModule { }
