import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-unknown-mark',
  templateUrl: 'bolt-unknown-mark.html',
  styleUrls: ['bolt-unknown-mark.scss']
})
export class BoltUnknownMarkComponent {
  @Input() hideLegend: boolean;
  @Input() legend: string;
  @Input() show: boolean;

  constructor() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.hideLegend = false;
    this.legend = 'Unknown';
    this.show = false;
  }
}
