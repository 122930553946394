import { Component, Input } from '@angular/core';
import { isUndefined as _isUndefined } from 'lodash';
import { AccordionItem } from '@bolt/ui-shared/common';


@Component({
  selector: 'bolt-list-accordion-field',
  templateUrl: 'bolt-list-accordion-field.html',
  styleUrls: ['bolt-list-accordion-field.scss']
})
export class BoltListAccordionFieldComponent {
  @Input() label: string;
  @Input() value: AccordionItem[] | undefined;
  @Input() limit: number;
  @Input() horizontalMode: boolean;

  constructor() {
    this.horizontalMode = true;
  }

  /**
   * Indicates if there is content to display (a non-empty value).
   *
   * @return boolean.
   */
  hasContentToDisplay(): boolean {
    const hasIt: boolean = !_isUndefined(this.value) && this.value.length > 0;
    return hasIt;
  }
}
