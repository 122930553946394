import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';

import { EntryMock } from './entry/entry.mock';


export class AuthHttpMock {
  protected entries: EntryMock[];

  constructor() {
    this.initialize();
  }

  /**
   * Mocks the deleting API call.
   *
   * @param url string
   * @param body string
   * @param options object
   * @returns Observable<HttpResponse<any>>
   */
  delete(url: string, body: string, options?: object): Observable<HttpResponse<any>> {
    return this.request(url);
  }

  /**
   * Mocks the getting API call.
   *
   * @param url string
   * @param options object
   * @returns Observable<HttpResponse<any>>
   */
  get(url: string, options?: object): Observable<HttpResponse<any>> {
    return this.request(url);
  }

  /**
   * Mocks the patching API call.
   *
   * @param url string
   * @param body string
   * @param options object
   * @returns Observable<HttpResponse<any>>
   */
  patch(url: string, body: string, options?: object): Observable<HttpResponse<any>> {
    return this.request(url);
  }

  /**
   * Mocks the posting API call.
   *
   * @param url string
   * @param body string
   * @param options object
   * @returns Observable<HttpResponse<any>>
   */
  post(url: string, body: string, options?: object): Observable<HttpResponse<any>> {
    return this.request(url);
  }

  /**
   * Mocks the put API call.
   *
   * @param url string
   * @param body string
   * @param options object
   * @returns Observable<HttpResponse<any>>
   */
  put(url: string, body: string, options?: object): Observable<HttpResponse<any>> {
    return this.request(url);
  }

  /**
   * Registries response arguments for an URL, to be used them when get() is called.
   *
   * @param url string
   * @param responseArguments any
   * @returns void
   */
  registryEntry(url: string, responseArguments: any): void {
    const entry: EntryMock = new EntryMock(url, responseArguments);
    this.entries.push(entry);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.entries = new Array();
  }

  /**
   * Mocks a request for the given URL.
   *
   * @param url string
   * @returns Observable<any>
   */
  protected request(url: string): Observable<any> {
    const aResponse: any = this.retrieveEntry(url).responseArguments;

    const observable: Observable<any> = new Observable(
      (observer: Observer<any>) => {
        if (aResponse instanceof HttpErrorResponse) {
          observer.error(aResponse);
        } else {
          observer.next(aResponse);
        }

        observer.complete();
      }
    );

    return observable;
  }

  /**
   * Retrieves the entry with the given URL.
   *
   * @param url string
   * @returns EntryMock
   */
  protected retrieveEntry(url: string): EntryMock {
    const entries: EntryMock[] = this.entries.filter(
      entry => {
        const found = (entry.url === url);
        return found;
      }
    );

    if (entries.length === 0) {
      throw new Error(`No registered entry for API call "${url}"`);
    } else {
      const entry = <EntryMock>entries.shift();
      return entry;
    }
  }
}
