import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-number-field',
  templateUrl: 'bolt-number-field.html',
  styleUrls: ['bolt-number-field.scss']
})
export class BoltNumberFieldComponent extends BoltInputField {
  @Input() expectedPattern: string;
  @Input() limit: number;

  value: number;

  constructor(public ngControl: NgControl) {
    super(ngControl);
  }
}
