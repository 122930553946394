import { isString as _isString } from 'lodash';

import { Direction } from './direction/direction.model';


export class Sort {
  protected field: string;
  protected direction: Direction;

  constructor(field: string, direction: Direction) {
    this.setDirection(direction);
    this.setField(field);
  }

  /**
   * Returns the direction.
   *
   * @returns Direction
   */
  getDirection(): Direction {
    return this.direction;
  }

  /**
   * Returns the field.
   *
   * @returns string
   */
  getField(): string {
    return this.field;
  }

  /**
   * Indicates if it is equals to the given sort.
   *
   * @param otherSort Sort
   * @returns boolean
   */
  isEqualsTo(otherSort: Sort): boolean {
    const isIt: boolean = (this.getField() === otherSort.getField());
    return isIt;
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    const output: string = `${this.getField()}:${this.getDirection()}`;
    return output;
  }

  /**
   * Set the direction.
   *
   * @param direction Direction
   * @throws Error if the given direction is invalid.
   * @returns void
   */
  protected setDirection(direction: Direction): void {
    if (direction instanceof Direction) {
      this.direction = direction;
    } else {
      throw new Error('Invalid direction given for a sort.');
    }
  }

  /**
   * Set the field.
   *
   * @param field string
   * @throws Error if the given field is invalid.
   * @returns void
   */
  protected setField(field: string): void {
    const errorMessage: string = 'Invalid field given for a sort.';

    if (_isString(field)) {
      const sanitizedField: string = field.trim();

      if (sanitizedField === '') {
        throw new Error(errorMessage);
      } else {
        this.field = sanitizedField;
      }
    } else {
      throw new Error(errorMessage);
    }
  }
}
