import { NgModule } from '@angular/core';
import { NotificationModule } from '@bolt/ui-shared/notification';
import { TooltipModule } from 'primeng';

import { BoltCopyLinkComponent } from './components/bolt-copy-link/bolt-copy-link.component';


@NgModule({
  imports: [
    NotificationModule,
    TooltipModule
  ],
  declarations: [
    BoltCopyLinkComponent
  ],
  exports: [
    BoltCopyLinkComponent
  ],
  providers: []
})
export class DataShareModule { }
