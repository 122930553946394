import { Injectable } from '@angular/core';
import { isString as _isString, isArray as _isArray, clone as _clone, flatten as _flatten } from 'lodash';

import { ProximityItem } from '../../models/proximity-item/proximity-item.model';


@Injectable()
export class ProximityManager {
  protected queries: Map<string, string[]>;
  protected collections: Map<string, ProximityItem[]>;
  protected filteredCollections: Map<string, ProximityItem[][][]>;

  constructor() {
    this.queries = new Map();
    this.collections = new Map();
    this.filteredCollections = new Map();
  }

  /**
   * Adds the given item.
   *
   * @param key string
   * @param item ProximityItem
   * @returns void
   */
  add(key: string, item: ProximityItem): void {
    const level1: number = (10000 - item.matchedWordsList.length);
    const level2: number = (10000 - (item.matchedWordsRatio * 100));
    const target: ProximityItem[][][] = this.filteredCollections.get(key);

    if (!target[level1]) {
      target[level1] = new Array();
    }

    if (!target[level1][level2]) {
      target[level1][level2] = new Array();
    }

    target[level1][level2].push(item);
  }

  /**
   * Adds a query for the given key.
   *
   * @param key string
   * @param value string
   * @returns void
   */
  addQuery(key: string, value: string): void {
    this.queries.set(key, value.trim().toLowerCase().split(' '));
    this.filterCollection(key);
  }

  /**
   * Returns the stored data as a sorted list by proximity.
   *
   * @param key string
   * @returns ProximityItem[]
   */
  getList(key: string): ProximityItem[] {
    const sortingCriteria: any = this.getSortCriteria();
    const origin: ProximityItem[][][] = this.filteredCollections.get(key);
    const result: ProximityItem[] = new Array();

    if (_isArray(origin)) {
      _flatten(origin).forEach(
        (list: ProximityItem[]) => {
          if (list) {
            list.sort(sortingCriteria).forEach(
              (item: ProximityItem) => {
                result.push(item);
              }
            );
          }
        }
      );
    }

    return result;
  }

  /**
   * Sets the given collection by the given key.
   *
   * @param key string
   * @param value ProximityItem[]
   * @returns void
   */
  setCollection(key: string, value: ProximityItem[]): void {
    this.collections.set(key, value);
    this.filteredCollections.set(key, [[value]]);
  }

  /**
   * Filters the given key's collection.
   *
   * @param key string
   * @returns void
   */
  protected filterCollection(key: string): void {
    this.resetFilteredCollection(key);

    const rawOptions: ProximityItem[] = this.collections.get(key);
    const query: string[] = this.queries.get(key);

    if (_isArray(query) && query.length > 0 && query.every((value: string) => value.length > 0)) {
      rawOptions.forEach(
        (option: ProximityItem) => {
          const matches: string[] = this.findInFilter(option.label, query);

          option.updateMatchedWordsList(matches);

          if (option.shouldBeShown()) {
            this.add(key, option);
          }
        }
      );
    } else {
      this.filteredCollections.set(key, [[rawOptions]]);
    }
  }

  /**
   * Finds and returns the words that match with filter.
   *
   * @param target string
   * @param query string[]
   * @returns string[]
   */
  protected findInFilter(target: string, query: string[]): string[] {
    const sanitizedTarget: string = target.trim().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');

    const list: string[] = query.filter(
      (word: string) => {
        const sanitizedWord: string = word.trim().toLocaleLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
        let wasFound: boolean;

        if (sanitizedWord) {
          wasFound = sanitizedTarget.includes(sanitizedWord);
        } else {
          wasFound = false;
        }

        return wasFound;
      }
    );

    return list;
  }

  /**
   * Returns the sorting criteria.
   *
   * @returns CallableFunction
   */
  protected getSortCriteria(): CallableFunction {
    const criteria: CallableFunction = (itemA: ProximityItem, itemB: ProximityItem) => {
      const labelA: string = itemA.label.trim().toLowerCase();
      const labelB: string = itemB.label.trim().toLowerCase();

      if (labelA < labelB) {
        return -1;
      } else {
        if (labelA > labelB) {
          return 1;
        } else {
          return 0;
        }
      }
    };

    return criteria;
  }

  /**
   * Resets the given key's filtered collection.
   *
   * @param key string
   * @returns void
   */
  protected resetFilteredCollection(key: string): void {
    this.filteredCollections.set(key, new Array());
  }
}
