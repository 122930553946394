import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';


@Directive({
  selector: '[boltAutoFocus]'
})
export class BoltAutoFocusDirective implements AfterViewInit {
  constructor(protected renderer: Renderer2, protected elementRef: ElementRef) { }

  ngAfterViewInit() {
    let element = this.elementRef.nativeElement;

    if (element.nodeName.toLowerCase() === 'form') {
      const elements = element.querySelectorAll(
        [
          'input:not([readonly])[type=text][name]',
          'input:not([readonly])[type=number][name]',
          'textarea:not([readonly])[name]',
        ].join(', ')
      );

      element = (elements[0] || element);
    }

    setTimeout(() => element['focus'].apply(element));
  }
}
