import { Pipe, PipeTransform } from '@angular/core';
import { kebabCase as _kebabCase } from 'lodash';


@Pipe({
  name: 'kebabCase'
})
export class KebabCasePipe implements PipeTransform {
  transform(value: string) {
    if (!value || !value.length) {
      return value;
    }

    return _kebabCase(value);
  }
}
