import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep as _cloneDeep, isNull as _isNull, isString as _isString } from 'lodash';


export class HttpError {
  static readonly INVALID_STATUS_CODE: number = -1;

  protected _statusCode: number;
  protected _message: string;
  protected _statusDetails: string;

  constructor(message: string, statusCode?: number, statusDetails?: string) {
    this._message = message.trim();
    this._statusCode = statusCode;
    this._statusDetails = (statusDetails ? statusDetails.trim() : undefined);
  }

  /**
   * Returns an instance using the given source.
   *
   * @param source any
   * @returns HttpError
   */
  static from(source: any): HttpError {
    let output: HttpError = new HttpError('Unknown error');

    if (_isString(source)) {
      output = new HttpError(source);
    } else if (source instanceof HttpError) {
      output = _cloneDeep(source);
    } else if (source instanceof Error) {
      output = new HttpError(source.message, undefined, source.name);
    } else if (source instanceof HttpErrorResponse) {
      const rawError: any = source.error;

      if (_isNull(rawError)) {
        output = new HttpError(source.message, source.status, source.statusText);
      } else {
        if (rawError.hasOwnProperty('err')) {
          if (rawError.err.hasOwnProperty('message')) {
            output = new HttpError(rawError.err.message, source.status, source.statusText);
          } else if (rawError.err.hasOwnProperty('error_description')) {
            output = new HttpError(rawError.err.error_description, source.status, source.statusText);
          }
        } else if (rawError.hasOwnProperty('message')) {
          output = new HttpError(rawError.message, source.status, source.statusText);
        } else if (rawError.hasOwnProperty('Message')) {
          output = new HttpError(rawError.Message, source.status, source.statusText);
        }
      }
    }

    return output;
  }

  get message(): string {
    return this._message;
  }

  get statusCode(): number {
    return this._statusCode;
  }

  get statusDetails(): string {
    return this._statusDetails;
  }

  /**
   * Indicates if the status code is a 200 code.
   *
   * @returns boolean
   */
  is200(): boolean {
    return this.isStatusCode(200);
  }

  /**
   * Indicates if the status code is a 404 code.
   *
   * @returns boolean
   */
  is404(): boolean {
    return this.isStatusCode(404);
  }

  /**
   * Indicates if the status code is a 409 code.
   *
   * @returns boolean
   */
  is409(): boolean {
    return this.isStatusCode(409);
  }

  /**
   * Indicates if the status code is a 500 code.
   *
   * @returns boolean
   */
  is500(): boolean {
    return this.isStatusCode(500);
  }

  /**
   * Indicates if the status code is a 504 code.
   *
   * @returns boolean
   */
  is504(): boolean {
    return (
      this.isStatusCode(504) ||
      // @todo: WAM special scenario for timeout. Remove this when WAM is removed from Bolt.
      (this.isStatusCode(500) && (this.message.toLowerCase() === `cannot read property 'statusCode' of null`))
    );
  }

  /**
   * Indicates if the status code is the invalid code.
   *
   * @returns boolean
   */
  isInvalid(): boolean {
    return this.isStatusCode(HttpError.INVALID_STATUS_CODE);
  }

  /**
   * Returns it as a string.
   *
   * @returns string
   */
  toString(): string {
    let output: string;
    const extra: string[] = new Array();

    if (this.statusCode !== undefined) {
      extra.push(this.statusCode.toString());
    }

    if (this.statusDetails !== undefined) {
      extra.push(this.statusDetails);
    }

    if (extra.length > 0) {
      output = `${this.message} (${extra.join(' ')})`;
    } else {
      output = this.message;
    }

    return output;
  }

  /**
   * Indicates if the given status code is the stored one.
   *
   * @returns boolean
   */
  protected isStatusCode(statusCode: number): boolean {
    const isIt: boolean = (this.statusCode === statusCode);
    return isIt;
  }
}
