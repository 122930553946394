import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isArray as _isArray, includes as _includes } from 'lodash';

import { TypeEnum } from './type.enum';


export class List {
  protected _items: SelectionItem[];
  protected _type: TypeEnum;

  constructor(type: TypeEnum, items: SelectionItem[]) {
    this.setType(type);
    this.setItems(items);
  }

  get items(): SelectionItem[] {
    return this._items;
  }

  get type(): TypeEnum {
    return this._type;
  }

  /**
   * Filters the current items.
   *
   * @param comparisonCriteria CallableFunction
   * @returns Array<SelectionItem>
   */
  filter(comparisonCriteria: CallableFunction): SelectionItem[] {
    const filteredItems: SelectionItem[] = this.items.filter(
      (item: SelectionItem) => comparisonCriteria(item)
    );

    return filteredItems;
  }

  /**
   * Finds an item in the current items by the given criteria
   *
   * @param comparisonCriteria CallableFunction
   * @returns SelectionItem
   */
  find(comparisonCriteria: CallableFunction): SelectionItem {
    const matched: SelectionItem = this.items.find(
      (item: SelectionItem) => comparisonCriteria(item)
    );

    return matched;
  }

  /**
   * Finds an item in the current items by label.
   *
   * @param label string
   * @returns SelectionItem
   */
  findByLabel(label: string): SelectionItem {
    const criteria: CallableFunction = (anItem: SelectionItem) => {
      return (anItem.label === label);
    };

    return this.find(criteria);
  }

  /**
   * Finds an item in the current items by value.
   *
   * @param value any
   * @returns SelectionItem
   */
  findByValue(value: any): SelectionItem {
    const criteria: CallableFunction = (anItem: SelectionItem) => {
      return (anItem.value === value);
    };

    return this.find(criteria);
  }

  /**
   * Indicates if it has items.
   *
   * @returns boolean
   */
  hasItems(): boolean {
    const hasIt: boolean = this.items.length > 0;
    return hasIt;
  }

  /**
   * Indicates if the current type is the given master data list type.
   *
   * @param type TypeEnum
   * @returns boolean
   */
  is(type: TypeEnum): boolean {
    const isIt: boolean = this.type === type;
    return isIt;
  }

  /**
   * Indicates if the given collection is valid.
   *
   * @param collection Array<SelectionItem>
   * @returns boolean
   */
  protected isValidCollection(collection: SelectionItem[]): boolean {
    const isIt: boolean =
      _isArray(collection) &&
      collection.every(
        (item: SelectionItem) => (item instanceof SelectionItem)
      );

    return isIt;
  }

  /**
   * Set the items with the given value.
   *
   * @param items Array<SelectionItem>
   * @throws Error
   * @returns void
   */
  protected setItems(items: SelectionItem[]): void {
    if (this.isValidCollection(items)) {
      this._items = items;
    } else {
      throw new Error('Invalid collection given for the list.');
    }
  }

  /**
   * Set the type with the given value.
   *
   * @param type TypeEnum
   * @throws Error
   * @returns void
   */
  protected setType(type: TypeEnum): void {
    if (_includes(TypeEnum, type)) {
      this._type = type;
    } else {
      throw new Error('Invalid type given for create a master data list.');
    }
  }
}
