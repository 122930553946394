export enum FunctionalMetadataEnum {
  displayDubTalent = 'displayDubTalent',
  excludeKidsMode = 'excludeKidsMode',
  graphicContentDisclaimer = 'graphicContentDisclaimer',
  heritageDisclaimer = 'heritageDisclaimer',
  includeKidsMode = 'includeKidsMode',
  productPlacementDisclaimer = 'productPlacementDisclaimer',
  pseDisclaimer = 'pseDisclaimer',
  smokingDisclaimer = 'smokingDisclaimer'
}
