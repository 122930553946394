import { CallInterface } from './call.interface';


export class ServiceMock {
  protected _calls: CallInterface[];

  constructor() {
    this.initialize();
  }

  /**
   * @deprecated Use getCalls().
   */
  get calls(): CallInterface[] {
    return this._calls;
  }

  /**
   * @deprecated Use setCalls().
   */
  set calls(calls: CallInterface[]) {
    this.setCalls(calls);
  }

  /**
   * Returns the calls.
   *
   * @returns CallInterface[]
   */
  getCalls(): CallInterface[] {
    return this._calls;
  }

  /**
   * Reset itself.
   *
   * @returns void
   */
  reset(): void {
    this._calls.forEach(
      (call: CallInterface) => {
        delete this[call.for];
      }
    );

    this._calls = new Array();
  }

  /**
   * Set the given call.
   *
   * @returns void
   */
  setCall(call: CallInterface): void {
    this[call.for] = call.do;
    this._calls.push(call);
  }

  /**
   * Set the given calls.
   *
   * @returns void
   */
  setCalls(calls: CallInterface[]): void {
    calls.forEach(
      (call: CallInterface) => {
        this.setCall(call);
      }
    );
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._calls = new Array();
  }
}
