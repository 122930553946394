import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { includes as _includes, isUndefined as _isUndefined } from 'lodash';

import { Attribute } from '../../models/functional-metadata/attribute/attribute.model';
import { FunctionalMetadataService } from '../../services/functional-metadata/functional-metadata.service';
import { LevelModeEnum } from '../../models/functional-metadata/level-mode/level-mode.enum';


@Component({
  selector: 'bolt-functional-metadata-details',
  templateUrl: 'bolt-functional-metadata-details.component.html',
  styleUrls: ['bolt-functional-metadata-details.component.scss']
})
export class BoltFunctionalMetadataDetailsComponent implements OnChanges {
  @Input() levelMode: LevelModeEnum;
  @Input() showComputedMessage: boolean;
  @Input() title: any;

  protected _items: Array<[Attribute, string?]>;

  constructor(
    protected functionalMetadataService: FunctionalMetadataService
  ) {
    this._items = [];
  }

  get items(): Array<[Attribute, string?]> {
    return this._items;
  }

  ngOnChanges(changes: SimpleChanges): void {
    let shouldSetupItems: boolean = false;

    if (changes.levelMode) {
      this.validateLevelMode();
      shouldSetupItems = true;
    }

    if (changes.title) {
      shouldSetupItems = true;
    }

    if (shouldSetupItems) {
      this.setupItems();
    }
  }

  /**
   * Sets up the items.
   *
   * @returns void
   */
  protected setupItems(): void {
    const items: Array<[Attribute, string?]> = [];

    if (!_isUndefined(this.title)) {
      this.functionalMetadataService.getAttributesByLevel(this.levelMode).forEach(
        (attribute: Attribute) => {
          if (this.title[attribute.getName()]) {
            const currentLocale: string = this.title.localizedFields[attribute.getName()];
            let displayedLocale: string;

            if (_isUndefined(currentLocale)) {
              displayedLocale = '*_*_*_*';
            } else {
              displayedLocale = currentLocale === this.title.locale ? undefined : currentLocale;
            }

            items.push([attribute, displayedLocale]);
          }
        }
      );
    }

    this._items = items;
  }

  /**
   * Validates if the stored Level Mode is valid.
   *
   * @throws Error
   * @returns void
   */
  protected validateLevelMode(): void {
    if (this.levelMode === LevelModeEnum.title) {
      throw new Error(`Functional Metadata - Invalid level mode: ${LevelModeEnum.title}.`);
    } else if (!_includes(LevelModeEnum, this.levelMode)) {
      throw new Error('Functional Metadata - Missing level mode.');
    }
  }
}
