import { Component, Input } from '@angular/core';
import { isString as _isString, isEmpty as _isEmpty } from 'lodash';


@Component({
  selector: 'bolt-authoritative-rating-reason',
  templateUrl: 'bolt-authoritative-rating-reason.component.html'
})
export class BoltAuthoritativeRatingReasonComponent {
  @Input() ratingSystemReasons: any[];
  @Input() theatricalAuthoritativeRatingReason: string;
  @Input() authoritativeRatingSystemReason: string;
  @Input() useParenthesis: boolean = false;
  @Input() isRtl: boolean = false;
  @Input() isAuthoritative: boolean = false;

  constructor() { }

  isAuthoritativeHomeEditionRating(): boolean {
    return _isString(this.authoritativeRatingSystemReason) && !_isEmpty(this.authoritativeRatingSystemReason);
  }

  isAuthoritativeTheatricalRating(): boolean {
    return _isString(this.theatricalAuthoritativeRatingReason) && !_isEmpty(this.theatricalAuthoritativeRatingReason);
  }

  getHomeEditionMessage(): string {
    const message = `${ this.authoritativeRatingSystemReason } `;

    return message + this.formatMessage('Authoritative - Apollo - Home Entertainment', this.useParenthesis);
  }

  getTheatricalMessage(): string {
    const message = `${ this.theatricalAuthoritativeRatingReason } `;

    return message + this.formatMessage('Authoritative - Apollo - Theatrical', this.useParenthesis);
  }

  protected formatMessage(message: string, useParenthesis: boolean): string {
    if (useParenthesis) {
      message = `(${message})`;
    } else {
      message = `- ${message}`;
    }

    return message;
  }
}
