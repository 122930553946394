export enum RoleTypeEnum {
  ACCOUNT_READ = 'ACCOUNT_READ',
  ADMIN = 'ADMIN',
  BOLT_AUTHORIZE = 'BOLT_AUTHORIZE',
  BOLT_READ = 'BOLT_READ',
  CAST_CREW = 'CAST_CREW',
  CAST_CREW_AUTHORIZE = 'CAST_CREW_AUTHORIZE',
  CAST_CREW_LOCALIZED = 'CAST_CREW_LOCALIZED',
  CAT = 'CAT',
  CAT_AUTHORIZE = 'CAT_AUTHORIZE',
  CAT_LOCALIZATION = 'CAT_LOCALIZATION',
  CAT_OV = 'CAT_OV',
  EXPORT = 'EXPORT',
  EXTERNAL_CONSUMER = 'EXTERNAL_CONSUMER',
  INSERTS_LOCALIZED = 'INSERTS_LOCALIZED',
  INSERTS_OV = 'INSERTS_OV',
  INSERTS_READ = 'INSERTS_READ',
  LOCALIZED_METADATA =  'LOCALIZED_METADATA',
  METADATA = 'METADATA',
  METADATA_HISTORY = 'METADATA_HISTORY',
  PROJECT = 'PROJECT',
  TASKS = 'TASKS',
  TASKS_MANAGER = 'TASKS_MANAGER',
  TITLE_READ = 'TITLE_READ'
}
