import {
  isObject as _isObject, isString as _isString, isArray as _isArray, isNumber as _isNumber,
  includes as _includes
} from 'lodash';

import { HttpError } from '@bolt/ui-shared/common';
import { ActionTypeEnum } from './action-type.enum';
import { UserRole } from '@bolt/ui-shared/auth';


export class Privilege {
  protected _action: ActionTypeEnum;
  protected _description: string;
  protected _id: number;
  protected _name: string;
  protected _roles: UserRole[];

  constructor(data: any) {
    this.initialize(data);
  }

  get action(): ActionTypeEnum {
    return this._action;
  }

  get description(): string {
    return this._description;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get roles(): UserRole[] {
    return this._roles;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @throws HttpError
   * @returns void
   */
  protected initialize(data: any): void {
    if (_isObject(data)) {
      this.setupAction(data);
      this.setupDescription(data);
      this.setupId(data);
      this.setupName(data);
      this.setupRoles(data);
    } else {
      throw new HttpError('Invalid data for creating a privilege');
    }
  }

  /**
   * Set up the action attribute.
   *
   * @param data any
   * @throws HttpError
   * @returns void
   */
  protected setupAction(data: any): void {
    if (_isString(data.name)) {
      const nameParts: string[] = data.name.split('-');
      const action: ActionTypeEnum = <ActionTypeEnum>nameParts[nameParts.length - 1];

      if (_includes(ActionTypeEnum, action)) {
        this._action = action;
      } else {
        throw new HttpError('Invalid name format for creating a privilege');
      }
    } else {
      throw new HttpError('Invalid name for creating a privilege');
    }
  }

  /**
   * Set up the description attribute.
   *
   * @param data any
   * @returns void
   */
  protected setupDescription(data: any): void {
    if (_isString(data.description)) {
      this._description = data.description;
    }
  }

  /**
   * Set up the id attribute.
   *
   * @param data any
   * @returns void
   */
  protected setupId(data: any): void {
    if (_isNumber(data.id)) {
      this._id = data.id;
    }
  }

  /**
   * Set up the name attribute.
   *
   * @param data any
   * @throws HttpError
   * @returns void
   */
  protected setupName(data: any): void {
    if (_isString(data.name)) {
      this._name = data.name;
    } else {
      throw new HttpError('Invalid name for creating a privilege');
    }
  }

  /**
   * Set up the roles attribute.
   *
   * @param data any
   * @returns void
   */
  protected setupRoles(data: any): void {
    if (_isArray(data.roles)) {
      this._roles = data.roles;
    } else {
      this._roles = new Array();
    }
  }
}
