import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WindowWrapperService } from '@bolt/ui-shared/common';


@Component({
  selector: 'bolt-copy-link',
  templateUrl: 'bolt-copy-link.component.html',
  styleUrls: ['bolt-copy-link.component.scss'],
  providers: [WindowWrapperService]
})
export class BoltCopyLinkComponent {
  @Input() disabled: boolean;
  @Input() href: string;
  @Input() target: string;
  @Input('class') cssClass: string;

  @Output('copied') copyEvent: EventEmitter<void>;

  protected wasCopiedFlag: boolean;

  constructor(protected windowWrapper: WindowWrapperService) {
    this.copyEvent = new EventEmitter();
    this.target = '_self';
    this.wasCopiedFlag = false;
  }

  /**
   * Copies the `href` and notifies it.
   *
   * @returns void
   */
  copyAndNotify(): void {
    this.windowWrapper.getNavigator().clipboard.writeText(this.href.replace(/^mailto:/, ''));
    this.wasCopiedFlag = true;
    this.copyEvent.emit();
  }

  /**
   * Indicates if it was copied.
   *
   * @returns boolean
   */
  wasCopied(): boolean {
    return this.wasCopiedFlag;
  }
}
