import { isNumber as _isNumber, isString as _isString } from 'lodash';

import { CountryInterface } from './country.interface';


export class Country implements CountryInterface {
  static readonly ALL_ID: number = 0;
  static readonly ALL_VALUE: string = '*';
  static readonly LOCALE_POSITION: number = 1;
  static readonly SOUTH_KOREA_ID: number = 222;
  static readonly SOUTH_KOREA_VALUE: string = 'KR';
  static readonly UNITED_STATES_ID: number = 20;
  static readonly UNITED_STATES_VALUE: string = 'US';

  protected _id: number;
  protected _iso31661: string = '';
  protected _name: string;
  protected _region: string = '';
  protected _regionId: number;

  constructor(data: any) {
    this._id = data.id;
    this._name = data.name;
    this._regionId = data.regionId;
    this._region = data.region;
    this._iso31661 = data.iso31661;
  }

  /**
   * Indicates if the given territory value is All.
   *
   * @param country number|Country|string
   * @returns boolean
   */
  static isAll(country: number | Country | string): boolean {
    let isIt: boolean;

    if (_isNumber(country)) {
      isIt = country === Country.ALL_ID;
    } else if (country instanceof Country) {
      isIt = (<Country>country).id === Country.ALL_ID;
    } else if (_isString(country)) {
      isIt = country === Country.ALL_VALUE;
    } else {
      isIt = false;
    }

    return isIt;
  }

  /**
   * Indicates if the given territory value is United States.
   *
   * @param country number | Country | string
   * @returns boolean
   */
  static isUnitedStates(country: number | Country | string): boolean {
    return Country.is(country, Country.SOUTH_KOREA_ID, Country.SOUTH_KOREA_VALUE);
  }

  /**
   * Indicates if the given territory value is South Korea.
   *
   * @param country number | string | Country
   * @returns boolean
   */
  static isSouthKorea(country: number | string | Country): boolean {
    return Country.is(country, Country.SOUTH_KOREA_ID, Country.SOUTH_KOREA_VALUE);
  }

  get code(): string {
    return this.iso31661;
  }

  get id(): number {
    return this._id;
  }

  get iso31661(): string {
    return this._iso31661;
  }

  get name(): string {
    return this._name;
  }

  get regionId(): number {
    return this._regionId;
  }

  get region(): string {
    return this._region;
  }

  /**
   * Indicates if it is for "All".
   *
   * @returns boolean
   */
  isAll(): boolean {
    return Country.isAll(this.id);
  }

  /**
   * Indicates if it is for "United States".
   *
   * @returns boolean
   */
  isUnitedStates(): boolean {
    return Country.isUnitedStates(this.id);
  }

  toLowerCase() {
    return this.name.toLowerCase();
  }

  toString() {
    return this.name;
  }

  /**
   * Indicates if the given territory matches with the expected ID or value.
   *
   * @param country number | string | Country
   * @returns boolean
   */
  protected static is(country: number | string | Country, expectedId: number, expectedValue: string): boolean {
    let isIt: boolean;

    if (_isNumber(country)) {
      isIt = country === expectedId;
    } else if (country instanceof Country) {
      isIt = (<Country>country).id === expectedId;
    } else if (_isString(country)) {
      isIt = country === expectedValue;
    } else {
      isIt = false;
    }

    return isIt;
  }
}
