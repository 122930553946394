import { AffixLocalization } from './affix-localization.model';
import { AffixInterface } from './affix.interface';
import { AffixType } from './affix.type';

export class Affix implements AffixInterface {
  constructor(
    public id: number,
    public type: AffixType,
    public value: string,
    public localizations?: any[]
  ) {
    this.loadLocalizations(localizations);
  }

  /**
   * Returns the value using the given locale.
   *
   * @param locale string
   * @return string
   */
  localizedValue(locale: string): string {
    let value: string = this.value;

    if (locale) {
      const searchingKeys = locale.split('_');

      if ((searchingKeys.length === 4) && this.localizations.length) {
        const localizations: AffixLocalization[] = this.localizations.filter(
          localization => {
            const currentKeys = localization.locale.split('_');
            const found: boolean = (
              (currentKeys[0] === searchingKeys[0]) &&
              ((currentKeys[1] === searchingKeys[1]) || (currentKeys[1] === '*')) &&
              ((currentKeys[2] === searchingKeys[2]) || (currentKeys[2] === '*')) &&
              ((currentKeys[3] === searchingKeys[3]) || (currentKeys[3] === '*'))
            );

            return found;
          }
        );

        if (localizations.length) {
          value = localizations.sort().pop().value;
        }
      }
    }

    return value;
  }

  toString() {
    return this.value;
  }

  /**
   * Loads the localizations.
   *
   * @param localizations any[]
   * @return void
   */
  protected loadLocalizations(localizations: any[]): void {
    this.localizations = [];

    if (localizations) {
      localizations.forEach(
        (localization: any) => {
          const affixLocalization: AffixLocalization = new AffixLocalization(
            localization.id, localization.locale, localization.value
          );

          this.localizations.push(affixLocalization);
        }
      );
    }
  }
}
