import { NgModule } from '@angular/core';

import { DefaultPipe } from './pipes/default/default.pipe';
import { KebabCasePipe } from './pipes/kebab-case/kebab-case.pipe';
import { StartCasePipe } from './pipes/start-case/start-case.pipe';


@NgModule({
  imports: [],
  declarations: [
    DefaultPipe,
    KebabCasePipe,
    StartCasePipe
  ],
  exports: [
    DefaultPipe,
    KebabCasePipe,
    StartCasePipe
  ],
  providers: []
})
export class DataTransformModule { }
