import { Component, Input } from '@angular/core';
import { FormControlDirective, NgControl } from '@angular/forms';
import { BoltInputField } from '../bolt-input-field/bolt-input-field.component';


@Component({
  selector: 'bolt-text-field',
  templateUrl: 'bolt-text-field.html',
  styleUrls: ['bolt-text-field.scss'],
  providers: [FormControlDirective]
})
export class BoltTextFieldComponent extends BoltInputField {
  @Input() expectedPattern: string;
  @Input() lengthLabel: string;
  @Input() lengthLimit: number;
  @Input() maxLength: number;
  @Input() showLengthCounter: boolean;
  @Input() textarea: boolean = false;
  @Input() textareaRows: number;
  @Input() autoFocus: boolean = false;

  value: string;

  constructor(public ngControl: NgControl) {
    super(ngControl);
    this.showLengthCounter = true;
  }

  /**
   * Indicates if ngControl has ngModel.
   *
   * @returns boolean
   */
  hasNgModel(): boolean {
    return (<any>this.ngControl).model;
  }

  /**
   * Indicates if it has to show the footer.
   *
   * @returns boolean
   */
  showFooter(): boolean {
    return this.showError || this.showLengthCounter;
  }
}
