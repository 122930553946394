import { find as _find, reject as _reject, groupBy as _groupBy } from 'lodash';

import { StormListItemInterface, StormListItem } from './storm-list-item.model';
import { StormListInterface } from './storm-list.interface';
import { StormListType } from './storm-list-type.enum';

export class StormList implements StormListInterface {

  collection: StormListItemInterface[] = [];

  constructor(public type: StormListType, items: StormListItemInterface[] = []) {
    items.forEach(item => {
      this.collection.push(new StormListItem({ id: item.id, value: item }));
    });
  }

  getRawCollection(): any[] {
    return this.collection.map(item => item.value);
  }

  /**
   * Returns the item for the given ID
   *
   * @param id number
   * @returns StormListItemInterface
   */
  getItem(id: number): StormListItemInterface {
    return _find(this.collection, ['id', id]);
  }

  /**
   * Returns those items matching the given predicate
   *
   * @param predicate any
   * @returns StormListItemInterface
   */
  findItem(predicate: any): StormListItemInterface {
    return _find(this.collection, predicate);
  }

  /**
   * Removes items matching the given predicate
   *
   * @param predicate any
   * @returns StormListInterface
   */
  removeItem(predicate: any): StormListInterface {
    this.collection = _reject(this.collection, predicate);
    return this;
  }

  /**
   * For those items matching the given predicate, set as the first ones on the list
   *
   * @param predicate any
   * @returns StormListInterface
   */
  setFirstItem(predicate: any): StormListInterface {
    const item = _find(this.collection, predicate);

    this.removeItem(predicate);
    this.collection = [item, ...this.collection];

    return this;
  }

  /**
   * Sort the items using the given CallableFunction
   * If no sortFunction given, sorting will be by item values
   *
   * @param sortFunction any
   * @return StormListInterface
   */
  sortItems(sortFunction?: any): StormListInterface {
    if (!sortFunction) {
      sortFunction = (a: StormListItemInterface, b: StormListItemInterface) => {

        if (a.value < b.value) {
          return -1;
        }
        return 1;
      };
    }

    this.collection.sort(sortFunction);

    return this;
  }

  groupBy(iteratee: any[] | CallableFunction | object | string): object {
    return _groupBy(this.collection, iteratee);
  }

}
