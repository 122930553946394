import { Directive, HostListener, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControlDirective, NgControl } from '@angular/forms';
import { isObject as _isObject } from 'lodash';


@Directive({ selector: '[boltInputTrim]' })
export class BoltInputTrimDirective implements OnInit {
  @Input() useNgModel: boolean = false;
  @Output() ngModelChanged: EventEmitter<any>;

  constructor(
    protected ngControl: NgControl,
    protected controlDirective: FormControlDirective,
    protected elementRef: ElementRef
  ) {
    this.ngModelChanged = new EventEmitter();
  }

  ngOnInit() {
    setTimeout(
      () => {
        this.trim();
      }
    );
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    this.trim();
  }

  /**
   * Applies the trim functionality over the form control or element reference.
   *
   * @returns void
   */
  protected trim(): void {
    if (this.useNgModel) {
      if (this.ngControl.dirty) {
        this.ngControl.control.setValue(this.ngControl.control.value?.trim() || '');
        this.ngModelChanged.emit(this.ngControl.control.value);
      }
    } else {
      if (_isObject(this.controlDirective)) {
        this.controlDirective.control.setValue(this.controlDirective.control.value?.trim() || '');
      } else {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value?.trim() || '';
      }
    }
  }
}
