import { NgModule } from '@angular/core';


@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    // Don't inject AppRoutesService here. It should be injected by the consumer defining the JSON file path.
  ]
})
export class RoutingModule { }
