import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'bolt-locking-status-indicator',
  templateUrl: './bolt-locking-status-indicator.html',
  styleUrls: ['./bolt-locking-status-indicator.scss']
})
export class BoltLockingStatusIndicatorComponent {
  @Input() exceptionMode: boolean;
  @Input() disabled: boolean;
  @Input() lockedIcon: string;
  @Input() lightMode: boolean;
  @Input() lockStatus: boolean;
  @Input() size: number;
  @Input() title: string;
  @Input() titlePosition: string;
  @Input() unlockedIcon: string;

  @Output('clicked') click: EventEmitter<undefined>;

  constructor() {
    this.exceptionMode = false;
    this.click = new EventEmitter();
    this.disabled = false;
    this.lightMode = false;
    this.lockedIcon = 'lock';
    this.size = 1;
    this.title = '';
    this.titlePosition = 'right';
    this.unlockedIcon = 'unlock';
  }

  /**
   * Notifies the click event.
   *
   * @returns void
   */
   notifyClick(): void {
    this.click.emit();
  }
}
