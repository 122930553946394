import { isNumber as _isNumber } from 'lodash';

export class Toggle {
  protected description: string;
  protected id: number;
  protected name: string;
  protected value: boolean;

  constructor(data: any) {
    this.initialize(data);
  }

  getDescription(): string {
    return this.description;
  }

  getId(): number {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getValue(): boolean {
    return this.value;
  }

  /**
   * Indicates if the current toggle is persisted.
   *
   * @returns boolean
   */
  isPersisted(): boolean {
    return _isNumber(this.id);
  }

  /**
   * Initialize the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this.description = data.description ? data.description : '';
    this.id = data.id;
    this.name = data.name;
    this.value = data.value;
  }
}
