import { Component, Input } from '@angular/core';
import { isArray as _isArray, isUndefined as _isUndefined, isNull as _isNull } from 'lodash';


@Component({
  selector: 'bolt-read-field',
  templateUrl: 'bolt-read-field.html',
  styleUrls: ['bolt-read-field.scss']
})
export class BoltReadFieldComponent {
  @Input() label: string;
  @Input() loading: boolean = false;
  @Input() routerLinkValue: string[];
  @Input() value: string | number;
  @Input() horizontalMode: boolean = true;
  @Input() showTips: boolean = false;

  constructor() { }

  /**
   * Indicates if it has a valid routerLink.
   *
   * @returns boolean
   */
  hasRouterLink(): boolean {
    return _isArray(this.routerLinkValue);
  }

  /**
   * Indicates if it has a valid value.
   *
   * @returns boolean
   */
  hasValue(): boolean {
    return !(_isUndefined(this.value) || _isNull(this.value));
  }

  /**
   * Indicates if is loading.
   *
   * @returns boolean
   */
  isLoading(): boolean {
    return this.loading;
  }
}
