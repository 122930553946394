export class ProximityItem {
  // TODO: Check if we can make them as protected (moved from bolt-ui and bolt-ui-bpm)
  data?: any;
  group?: string;
  label: string;
  value: any;

  protected _matchedWordsList: string[];
  protected _wordsIn: string[];

  constructor(label: string, value: any, group?: string, data?: any) {
    this.data = data;
    this.group = group;
    this.label = label;
    this.value = value;
    this._wordsIn = this.label.toLocaleLowerCase().trim().replace(/\s+/, ' ').split(' ');

    this.updateMatchedWordsList(new Array());
  }

  get matchedWordsList(): string[] {
    return this._matchedWordsList;
  }

  get matchedWordsRatio(): number {
    const relativeError: number = Math.abs(this.wordsIn.length - this.matchedWordsList.length) / this.wordsIn.length * 100;
    const ratio = (parseFloat((100 - relativeError).toFixed(2)));

    return ratio;
  }

  get wordsIn(): string[] {
    return this._wordsIn;
  }

  /**
   * Indicates if it should be shown.
   *
   * @returns boolean
   */
  shouldBeShown(): boolean {
    const should: boolean = (
      this.matchedWordsList.length > 0
    );

    return should;
  }

  /**
   * Updates the filtered words.
   *
   * @param matches string[]
   * @returns void
   */
  updateMatchedWordsList(matches: string[]): void {
    this._matchedWordsList = matches;
  }
}
