import { isNumber as _isNumber, isObject as _isObject, isString as _isString } from 'lodash';


export class SecondaryProductAssociation {
  static readonly ALL_ID: number = 0;

  protected _id: number;
  protected _name: string;

  constructor(data: any) {
    this.setId(data);
    this.setName(data);
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Set the ID with the given data.
   *
   * @param data any
   * @throws Error if the given data has an invalid ID.
   * @returns void
   */
  protected setId(data: any): void {
    if (_isObject(data) && _isNumber((data as any).id)) {
      this._id = (data as any).id;
    } else {
      throw new Error('Invalid ID given.');
    }
  }

  /**
   * Set the name with the given data.
   *
   * @param data any
   * @throws Error if the given data has an invalid name.
   * @returns void
   */
  protected setName(data: any): void {
    if (_isObject(data) && _isString((data as any).name)) {
      this._name = (data as any).name.trim();
    } else {
      throw new Error('Invalid name given.');
    }
  }
}
