import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import {
  isArray as _isArray, isObject as _isObject, isEqual as _isEqual, isString as _isString, isUndefined as _isUndefined
} from 'lodash';

import { AccordionItem } from '../../models/accordion-item/accordion-item.model';


@Component({
  selector: 'bolt-list-accordion',
  templateUrl: 'bolt-list-accordion.html',
  styleUrls: ['bolt-list-accordion.scss']
})
export class BoltListAccordionComponent implements OnChanges {
  @Input() list: AccordionItem[] | undefined;
  @Input() limit: number;

  @Output('collapsed') collapseEvent: EventEmitter<undefined>;
  @Output('expanded') expandEvent: EventEmitter<undefined>;

  protected _isCollapsed: boolean;
  protected _slicedList: any[];

  constructor() {
    this.collapseEvent = new EventEmitter();
    this.expandEvent = new EventEmitter();
    this.list = [];

    this.setupSlicedList(0);
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  get slicedList(): any[] {
    return this._slicedList;
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldRunSetup: boolean =
      (_isObject(changes.limit) || (_isObject(changes.list) && !_isEqual(changes.list.previousValue, changes.list.currentValue))) &&
      this.hasContentToDisplay();

    if (shouldRunSetup) {
      this.setupSlicedList(this.limit);
    }
  }

  /**
   * Renders less content in the list accorder to the limit.
   *
   * @returns void
   */
  collapse(): void {
    this.setupSlicedList(this.limit);
    this.collapseEvent.emit();
  }

  /**
   * Renders all content on the list.
   *
   * @returns void
   */
  expand(): void {
    this.setupSlicedList(this.list.length);
    this.expandEvent.emit();
  }

  /**
   * Indicates if there is content to display (a non-empty value).
   *
   * @return boolean.
   */
  hasContentToDisplay(): boolean {
    const hasIt: boolean = !_isUndefined(this.list) && this.list.length > 0;
    return hasIt;
  }

  /**
   * Sets up the sliced list using the given limit.
   *
   * @params newLimit number
   * @returns void
   */
  protected setupSlicedList(newLimit: number): void {
    if (this.list.length > newLimit) {
      this._isCollapsed = true;
      this._slicedList = this.list.slice(0, newLimit);
    } else {
      this._isCollapsed = false;
      this._slicedList = this.list;
    }
  }
}
