import { HttpError } from '@bolt/ui-shared/common';
import { isNull as _isNull, isString as _isString, isUndefined as _isUndefined } from 'lodash';


export class SelectionItem {
  protected _group: string;
  protected _label: string;
  protected _source: any;
  protected _value: any;

  constructor(label: string, value: any, source?: any, group?: string) {
    this.setLabel(label);
    this.setValue(value);
    this.setSource(source);
    this.setGroup(group);
  }

  get group(): string {
    return this._group;
  }

  get label(): string {
    return this._label;
  }

  get source(): any {
    return this._source;
  }

  get value(): any {
    return this._value;
  }

  /**
   * Returns a new raw object with the inner content.
   *
   * @returns object
   */
  getRawObject(): object {
    const rawData: object = {
      label: this.label,
      value: this.value,
      group: this.group
    };

    return rawData;
  }

  /**
   * Indicates if it has group.
   *
   * @returns boolean
   */
  hasGroup(): boolean {
    const hasIt: boolean = _isString(this._group);
    return hasIt;
  }

  /**
   * Indicates it has the source related to the label and value.
   *
   * @returns void
   */
  hasSource(): boolean {
    const hasIt: boolean = (!_isUndefined(this.source) && !_isNull(this.source));
    return hasIt;
  }

  /**
   * Set the group.
   *
   * @param group any
   * @returns void
   */
  protected setGroup(group: string): void {
    this._group = group;
  }

  /**
   * Set the label.
   *
   * @param label string
   * @throws ErrorHelper
   * @returns void
   */
  protected setLabel(label: string): void {
    if (_isString(label) && label.length > 0) {
      this._label = label;
    } else {
      throw new HttpError('Invalid label given for a selection item.');
    }
  }

  /**
   * Set the source.
   *
   * @param source any
   * @returns void
   */
  protected setSource(source: any): void {
    this._source = source;
  }

  /**
   * Set the value.
   *
   * @param value any
   * @throws ErrorHelper
   * @returns void
   */
  protected setValue(value: any): void {
    if (!_isUndefined(value)) {
      this._value = value;
    } else {
      throw new HttpError('Invalid value given for a selection item.');
    }
  }
}
