import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { isObject as _isObject, includes as _includes, isNull as _isNull, isString as _isString, isBoolean as _isBoolean } from 'lodash';

import { Attribute } from '../../models/functional-metadata/attribute/attribute.model';
import { FunctionalMetadataEnum } from '../../models/functional-metadata/functional-metadata.enum';
import { FunctionalMetadataService } from '../../services/functional-metadata/functional-metadata.service';
import { LevelModeEnum } from '../../models/functional-metadata/level-mode/level-mode.enum';


@Component({
  selector: 'bolt-functional-metadata',
  templateUrl: 'bolt-functional-metadata.component.html',
  styleUrls: ['bolt-functional-metadata.component.scss']
})
export class BoltFunctionalMetadataComponent implements OnChanges {
  @Input() creationMode: boolean;
  @Input() form: FormGroup;
  @Input() levelMode: LevelModeEnum;
  @Input() locale: string | undefined;
  @Input() localizedFields: any | undefined;

  readonly localeRoot: string = '*_*_*_*';
  readonly kidsModeMessage: string = `Kids-mode fields are mutually exclusive and cannot be modified on localizations when "Negative Stereotype Advisory" ' is checked on Root Title (${this.localeRoot}).`;

  protected _attributes: Attribute[];

  constructor(
    protected functionalMetadataService: FunctionalMetadataService
  ) {
    this._attributes = [];
    this.creationMode = true;
  }

  get attributes(): Attribute[] {
    return this._attributes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_isObject(changes.levelMode) || _isObject(changes.form)) {
      this.validateLevelMode();

      this._attributes = this.functionalMetadataService.getAttributesByLevel(this.levelMode);

      this.validateForm();
      this.setupControls();
    }
  }

  /**
   * Retrieves the computed-from localization.
   *
   * @returns string
   */
  getComputedFromLocalization(attribute: Attribute): string {
    const hasLocale: boolean =
      _isObject(this.localizedFields) &&
      _isString(this.localizedFields[attribute.getName()]) &&
      this.localizedFields[attribute.getName()].length > 0;

    if (hasLocale) {
      return this.localizedFields[attribute.getName()];
    } else {
      return this.localeRoot;
    }
  }

  /**
   * Retrieves the switch for the given key.
   *
   * @param key FunctionMetadataEnum
   * @returns AbstractControl
   */
  getSwitchFor(key: FunctionalMetadataEnum): AbstractControl {
    return this.form.get(key + 'Override');
  }

  /**
   * Handles the check change for the given attribute.
   *
   * @param key FunctionalMetadataEnum
   * @param isChecked boolean
   * @returns void
   */
  handleCheckChangeFor(key: FunctionalMetadataEnum, isChecked: boolean): void {
    this.updateFieldValueFor(key, isChecked, false);

    // After updating the attribute in the above line, we get the value.
    const checkValue: boolean | null = this.getCheckFor(key).value;

    switch (key) {
      case FunctionalMetadataEnum.heritageDisclaimer:
        // We update the value of Exclude Kids Mode after updating the value of Heritage Disclaimer.
        this.updateFieldValueFor(FunctionalMetadataEnum.excludeKidsMode, checkValue, true);
        break;
      case FunctionalMetadataEnum.excludeKidsMode:
        if (checkValue) {
          // We update the value of Include Kids Mode with the opposite value of Exclude Kids Mode.
          this.updateFieldValueFor(FunctionalMetadataEnum.includeKidsMode, null, false);
        }
        break;
      case FunctionalMetadataEnum.includeKidsMode:
        if (checkValue) {
          // We update the value of Exclude Kids Mode with the opposite value of Include Kids Mode.
          this.updateFieldValueFor(FunctionalMetadataEnum.excludeKidsMode, null, false);
        }
        break;
      default:
        // Do nothing
        break;
    }
  }

  /**
   * Handles the switch change for the given attribute.
   *
   * @param attribute Attribute
   * @param isOverride boolean
   * @returns void
   */
  handleSwitchChangeFor(attribute: Attribute, isOverride: boolean): void {
    this.doHandleSwitchChangeFor(attribute.getName(), isOverride, !this.getCheckFor(attribute.getName()).value);

    if (this.isExcludeKidsMode(attribute)) {
      this.doHandleSwitchChangeFor(FunctionalMetadataEnum.includeKidsMode, isOverride, isOverride ? false : null);
    } else if (this.isIncludeKidsMode(attribute)) {
      this.doHandleSwitchChangeFor(FunctionalMetadataEnum.excludeKidsMode, isOverride, isOverride ? false : null);
    }
  }

  /**
   * Indicates if there is a localization for the given attribute.
   *
   * @param attribute Attribute
   * @returns boolean
   */
  hasComputedFromLocalization(attribute: Attribute): boolean {
    let hasIt: boolean = false;

    if (this.isHeritageDisclaimer(attribute)) {
      hasIt = true;
    } else {
      if (this.getComputedFromLocalization(attribute) === this.locale) {
        hasIt = false;
      } else {
        const hasSwitcherOnComputed: boolean = attribute.hasSwitch() && !this.getSwitchFor(attribute.getName()).value;

        if (this.localeRoot === this.getComputedFromLocalization(attribute)) {
          hasIt = this.functionalMetadataService.getAttributesByLevel(LevelModeEnum.title).some(
            (titleAttribute) => titleAttribute.getName() === attribute.getName()
          );
        } else if (this.isExcludeKidsMode(attribute)) {
          hasIt = this.getCheckFor(FunctionalMetadataEnum.heritageDisclaimer).value || hasSwitcherOnComputed;
        } else if (this.isIncludeKidsMode(attribute)) {
          hasIt = this.getCheckFor(FunctionalMetadataEnum.heritageDisclaimer).value ? false : hasSwitcherOnComputed;
        } else {
          hasIt = hasSwitcherOnComputed;
        }
      }
    }

    return hasIt;
  }

  /**
   * Indicates if it has to display the "Computed From" column.
   *
   * @returns boolean
   */
  hasDisplayComputedFrom(): boolean {
    const hasIt: boolean = !this.creationMode && !this.isTitleLevelMode();
    return hasIt;
  }

  /**
   * Indicates if it has to display the checkbox for the given attribute.
   *
   * @param attribute Attribute
   * @returns boolean
   */
  hasDisplayControlsFor(attribute: Attribute): boolean {
    const hasIt: boolean =
      !this.isTitleLevelMode() ||
      (attribute.getName() !== FunctionalMetadataEnum.excludeKidsMode) ||
      this.getCheckFor(FunctionalMetadataEnum.heritageDisclaimer).value;

    return hasIt;
  }

  /**
   * Indicates if it has to display the kids-mode dependency message.
   *
   * @returns boolean
   */
  hasDisplayKidsModeDependency(): boolean {
    return !this.isTitleLevelMode();
  }

  /**
   * Indicates if it has to display the switch for the given attribute.
   *
   * @param attribute Attribute
   * @returns boolean
   */
  hasDisplaySwitchFor(attribute: Attribute): boolean {
    let hasIt: boolean;

    if (attribute.hasSwitch()) {
      if (this.isExcludeKidsMode(attribute) || this.isIncludeKidsMode(attribute)) {
        hasIt = !this.getCheckFor(FunctionalMetadataEnum.heritageDisclaimer).value;
      } else {
        hasIt = true;
      }
    } else {
      hasIt = false;
    }

    return hasIt;
  }

  /**
   * Indicates if the given attribute is kids-mode dependent.
   *
   * @param attribute Attribute
   * @returns boolean
   */
  isKidsModeDependent(attribute: Attribute): boolean {
    const isIt: boolean = this.isExcludeKidsMode(attribute) || this.isIncludeKidsMode(attribute);
    return isIt;
  }

  /**
   * Sets up the controls according to the valid stored Level Mode.
   *
   * @returns void
   */
  setupControls(): void {
    if (this.form.enabled) {
      this.attributes.forEach(
        (attribute: Attribute) => {
          const check: AbstractControl = this.getCheckFor(attribute.getName());

          if (attribute.isEditable()) {
            const hasSwitcherOnComputed: boolean = attribute.hasSwitch() && !this.getSwitchFor(attribute.getName()).value;

            if (hasSwitcherOnComputed) {
              check.disable();
            } else {
              if (this.isExcludeKidsMode(attribute) || this.isIncludeKidsMode(attribute)) {
                if (this.getCheckFor(FunctionalMetadataEnum.heritageDisclaimer).value) {
                  check.disable();
                } else {
                  check.enable();
                }
              } else {
                check.enable();
              }
            }
          } else {
            check.disable();
          }
        }
      );

      if (this.isAccountLevelMode() && this.getCheckFor(FunctionalMetadataEnum.excludeKidsMode).disabled) {
        // On Account Mode, if Exclude Kids Mode is disabled, Include Kids Mode should be disabled too.
        this.getCheckFor(FunctionalMetadataEnum.includeKidsMode).disable();
      }
    }
  }

  /**
   * Does the switch change for the given parameters.
   *
   * @param key FunctionalMetadataEnum
   * @param isOverride boolean
   * @param newValue boolean | null
   * @returns void
   */
  protected doHandleSwitchChangeFor(key: FunctionalMetadataEnum, isOverride: boolean, newValue: boolean | null): void {
    const check: AbstractControl = this.getCheckFor(key);
    const switcher: AbstractControl = this.getSwitchFor(key);

    if (_isObject(switcher)) {
      switcher.setValue(isOverride);
    }

    this.handleCheckChangeFor(key, newValue);

    if (isOverride) {
      check.enable();
    } else {
      check.disable();
    }
  }

  /**
   * Retrieves the check for the given key.
   *
   * @param key FunctionMetadataEnum
   * @returns AbstractControl
   */
  protected getCheckFor(key: FunctionalMetadataEnum): AbstractControl {
    return this.form.get(key);
  }

  /**
   * Indicates if the Level Mode is for Account.
   *
   * @returns boolean
   */
  protected isAccountLevelMode(): boolean {
    const isIt: boolean = this.levelMode === LevelModeEnum.account;
    return isIt;
  }

  /**
   * Indicates if the given attribute is for "Exclude Kids Mode".
   *
   * @param attribute Attribute
   * @returns boolean
   */
  protected isExcludeKidsMode(attribute: Attribute): boolean {
    const isIt: boolean = attribute.getName() === FunctionalMetadataEnum.excludeKidsMode;
    return isIt;
  }

  /**
   * Indicates if the given attribute is for "Heritage Disclaimer".
   *
   * @param attribute Attribute
   * @returns boolean
   */
  protected isHeritageDisclaimer(attribute: Attribute): boolean {
    const isIt: boolean = attribute.getName() === FunctionalMetadataEnum.heritageDisclaimer;
    return isIt;
  }

  /**
   * Indicates if the given attribute is for "Include Kids Mode".
   *
   * @param attribute Attribute
   * @returns boolean
   */
  protected isIncludeKidsMode(attribute: Attribute): boolean {
    const isIt: boolean = attribute.getName() === FunctionalMetadataEnum.includeKidsMode;
    return isIt;
  }

  /**
   * Indicates if the Level Mode is for Territory.
   *
   * @returns boolean
   */
  protected isTerritoryLevelMode(): boolean {
    const isIt: boolean = this.levelMode === LevelModeEnum.territory;
    return isIt;
  }

  /**
   * Indicates if the Level Mode is for Title.
   *
   * @returns boolean
   */
  protected isTitleLevelMode(): boolean {
    const isIt: boolean = this.levelMode === LevelModeEnum.title;
    return isIt;
  }

  /**
   * Ensures and updates the value for the given field.
   *
   * @param field FunctionalMetadataEnum
   * @param newValue boolean
   * @param forceDisabled boolean
   * @returns void
   */
  protected updateFieldValueFor(field: FunctionalMetadataEnum, newValue: boolean, forceDisabled: boolean): void {
    const check: AbstractControl = this.getCheckFor(field);
    let parsedValue: boolean | null;

    if (this.isTitleLevelMode()) {
      parsedValue = newValue ? true : null;
    } else {
      const attribute: Attribute = this.attributes.find(
        (current: Attribute) => current.getName() === field
      );

      if (this.hasDisplaySwitchFor(attribute)) {
        const switcher: AbstractControl = this.getSwitchFor(attribute.getName());
        parsedValue = switcher.value ? newValue : null;
      } else {
        parsedValue = newValue ? true : null;
      }
    }

    check.setValue(parsedValue);

    if (forceDisabled) {
      check.disable();
    }
  }

  /**
   * Validates if the stored Level Mode is valid.
   *
   * @throws Error
   * @returns void
   */
  protected validateLevelMode(): void {
    if (this.isTerritoryLevelMode()) {
      throw new Error(`Functional Metadata - Invalid level mode: ${LevelModeEnum.territory}.`);
    } else if (!_includes(LevelModeEnum, this.levelMode)) {
      throw new Error('Functional Metadata - Missing level mode.');
    }
  }

  /**
   * Validates if the stored form has all the mandatory controls.
   *
   * @throws Error
   * @returns void
   */
  protected validateForm(): void {
    const missingFields: string[] = [];

    this.attributes.forEach(
      (attribute: Attribute) => {
        const mainField: AbstractControl = this.getCheckFor(attribute.getName());

        if (_isNull(mainField)) {
          missingFields.push(attribute.getName());
        }

        if (attribute.hasSwitch()) {
          const overrideName: string = attribute.getName() + 'Override';
          const overrideField: AbstractControl = this.form.get(overrideName);

          if (_isNull(overrideField)) {
            missingFields.push(overrideName);
          }
        }
      }
    );

    if (missingFields.length > 0) {
      throw new Error(`Functional Metadata - Missing required form controls: ${missingFields.join(', ')}.`);
    }
  }
}
