export class OutageNotificationConfig {
  private message: string;
  private duration: number;
  private hidden: boolean;

  private readonly defaultDuration = 10800; // Three hours in seconds
  private readonly defaultMessage = 'now until further notice';

  constructor(message?: string, duration?: number, hidden?: boolean) {
    this.message = message || this.defaultMessage;
    this.hidden = hidden || false;
    this.duration = duration || this.defaultDuration;
  }

  /**
   * Gets the stored message
   *
   * @returns string
   */
  getMessage(): string {
    return this.message;
  }

  /**
   * Gets the stored duration
   *
   * @returns number
   */
  getDuration(): number {
    return this.duration;
  }

  /**
   * Gets the hidden value
   * @returns boolean
   */
  isHidden(): boolean {
    return this.hidden;
  }
}
