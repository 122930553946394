import { RatingSystemInterface } from '../rating-system/rating-system.model';
import { RatingSystemReasonInterface } from './rating-system-reason.interface';

export class RatingSystemReason implements RatingSystemReasonInterface {

  constructor(
    public id: number,
    public name: string,
    public ratingSystems: RatingSystemInterface[] = []
  ) { }

  toString() {
    return this.name;
  }
}
