import { Pipe, PipeTransform } from '@angular/core';
import { startCase as _startCase } from 'lodash';


@Pipe({
  name: 'startCase'
})
export class StartCasePipe implements PipeTransform {
  transform(value: string) {
    if (!value || !value.length) {
      return value;
    }

    return _startCase(value);
  }
}
