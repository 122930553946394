import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { clone as _clone, isObject as _isObject, isArray as _isArray, isString as _isString } from 'lodash';

import { ProximityItem } from '../../models/proximity-item/proximity-item.model';
import { ProximityManager } from '../../services/proximity-manager/proximity-manager.service';


@Component({
  selector: 'bolt-proximity-dropdown',
  templateUrl: 'bolt-proximity-dropdown.html',
  styleUrls: ['bolt-proximity-dropdown.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BoltProximityDropdownComponent),
    multi: true
  }]
})
export class BoltProximityDropdownComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() disabled: boolean;
  @Input() itemSize: number;
  @Input() key: string;
  @Input() options: ProximityItem[];
  @Input() placeholder: string;
  @Input() scrollHeight: string;
  @Input() virtualScroll: boolean;
  @Output('changed') changeEvent: EventEmitter<any>;

  selection: number;

  protected _filteredOptions: ProximityItem[];
  protected onModelChange: CallableFunction;
  protected onModelTouched: CallableFunction;

  constructor(protected proximityManager: ProximityManager) {
    this.disabled = false;
    this.changeEvent = new EventEmitter();
    this.virtualScroll = true;
    this.setFilteredOptions();
  }

  ngOnInit() {
    if (!_isString(this.key)) {
      throw new Error('Invalid key value given for BoltProximityDropdownComponent');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.options) && _isArray(changes.options.currentValue)) {
      this.setOptions();
    }
  }

  get filteredOptions(): ProximityItem[] {
    return this._filteredOptions;
  }

  /**
   * Emits the change.
   *
   * @param $event any
   * @returns void
   */
  emitChange($event: any): void {
    if (this.onModelChange) {
      this.onModelChange($event.value);
    }

    this.changeEvent.emit($event.value);
  }

  /**
   * Handles the filter change flow.
   *
   * @returns void
   */
  onFilterChange(): void {
    this.setFilteredOptions();
  }

  /**
   * Registers on change event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnChange(fn: CallableFunction): void {
    this.onModelChange = fn;
  }

  /**
   * Registers on touch event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnTouched(fn: CallableFunction): void {
    this.onModelTouched = fn;
  }

  /**
   * Set the disabled state.
   *
   * @param val boolean
   * @returns void
   */
  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  /**
   * Writes a value.
   *
   * @param value any
   * @returns void
   */
  writeValue(value: any): void {
    this.selection = value;
  }

  /**
   * Set the filtered options array.
   *
   * @returns void
   */
  protected setFilteredOptions(): void {
    setTimeout(() => {
      this._filteredOptions = this.proximityManager.getList(this.key);
    });
  }

  /**
   * Set the options array.
   *
   * @returns void
   */
  protected setOptions(): void {
    this.proximityManager.setCollection(this.key, this.options);
    this.setFilteredOptions();
  }
}
