import { isNumber as _isNumber, isString as _isString } from 'lodash';

import { ProductTypeInterface } from './product-type.interface';


export class ProductType implements ProductTypeInterface {
  static readonly ALL_ID: number = 0;
  static readonly ALL_VALUE: string = '*';
  static readonly LOCALE_POSITION: number = 2;

  protected _id: number;
  protected _name: string;
  protected _code: string;

  constructor(id: number, name: string, code: string = '') {
    this._id = id;
    this._name = name;
    this._code = code;
  }

  /**
   * Indicates if the given product type value is All.
   *
   * @param productType number|ProductType|string
   * @returns boolean
   */
  static isAll(productType: number | ProductType | string): boolean {
    let isIt: boolean;

    if (_isNumber(productType)) {
      isIt = productType === ProductType.ALL_ID;
    } else if (productType instanceof ProductType) {
      isIt = (<ProductType>productType).id === ProductType.ALL_ID;
    } else if (_isString(productType)) {
      isIt = productType === ProductType.ALL_VALUE;
    } else {
      isIt = false;
    }

    return isIt;
  }

  get code(): string {
    return this._code;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Indicates if it is for "All".
   *
   * @returns boolean
   */
  isAll(): boolean {
    return ProductType.isAll(this.id);
  }

  toLowerCase() {
    return this.name.toLowerCase();
  }

  toString() {
    return this.name;
  }
}
