
import { isObject as _isObject, includes as _includes, isUndefined as _isUndefined } from 'lodash';
import { FunctionalMetadataEnum } from '../functional-metadata.enum';


export class Attribute {
  protected _hasSwitch: boolean;
  protected _isEditable: boolean;
  protected name: FunctionalMetadataEnum;
  protected description: string;

  constructor(
    name: FunctionalMetadataEnum,
    isEditable: boolean,
    hasSwitch: boolean
  ) {
    if (_includes(FunctionalMetadataEnum, name)) {
      this.name = name;
      this._isEditable = isEditable;
      this._hasSwitch = hasSwitch;

      this.discoverDescription();
    } else {
      throw new Error(`Invalid attribute enum given: ${name}`);
    }
  }

  /**
   * Gets the description.
   *
   * @returns string
   */
  getDescription(): string {
    return this.description;
  }

  /**
   * Gets the name.
   *
   * @returns FunctionalMetadataEnum
   */
  getName(): FunctionalMetadataEnum {
    return this.name;
  }

  /**
   * Indicates if it is editable.
   *
   * @returns boolean
   */
  isEditable(): boolean {
    return this._isEditable;
  }

  /**
   * Indicates if it has a switch.
   *
   * @returns boolean
   */
  hasSwitch(): boolean {
    return this._hasSwitch;
  }

  /**
   * Discovers the description.
   *
   * @returns void
   */
  protected discoverDescription(): void {
    switch (this.name) {
      case FunctionalMetadataEnum.displayDubTalent:
        this.description = 'Display Dub Talent Override';
        break;
      case FunctionalMetadataEnum.excludeKidsMode:
        this.description = 'Exclude Kids Mode';
        break;
      case FunctionalMetadataEnum.graphicContentDisclaimer:
        this.description = 'Graphic Content Disclaimer';
        break;
      case FunctionalMetadataEnum.heritageDisclaimer:
        this.description = 'Negative Stereotype Advisory';
        break;
      case FunctionalMetadataEnum.includeKidsMode:
        this.description = 'Include Kids Mode';
        break;
      case FunctionalMetadataEnum.productPlacementDisclaimer:
        this.description = 'Product Placement Advisory';
        break;
      case FunctionalMetadataEnum.pseDisclaimer:
        this.description = 'Photosensitivity Advisory';
        break;
      default:
        this.description = 'Smoking Advisory';
        break;
    }
  }
}
