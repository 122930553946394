import { Component, Input } from '@angular/core';
import { Rating } from '@bolt/ui-shared/master-data';
import { isNumber as _isNumber, isFunction as _isFunction } from 'lodash';

@Component({
  selector: 'bolt-authoritative-rating',
  templateUrl: 'bolt-authoritative-rating.component.html'
})
export class BoltAuthoritativeRatingComponent {
  @Input() ratings: Rating[];
  @Input() useParenthesis: boolean = false;
  @Input() isRtl: boolean = false;

  constructor() { }

  getMessage(rating: Rating): string {
    let message: string = '';

    if (_isFunction(rating.isAuthoritative) && rating.isAuthoritative()) {
      message = `Authoritative - Apollo - ${ rating.isTheatrical() ? 'Theatrical' : 'Home Entertainment'}`;

      if (this.useParenthesis) {
        message = `(${message})`;
      }
    } else {
      message = rating?.description;
    }

    return message;
  }

  /**
   * Returns the human readable name of the rating system.
   *
   * @remarks
   * Must of the time, we receive rating.ratingSystemId as the computed value,
   * but sometimes, specially now that we have BOLT ratings,
   * we may not receive the computed value but the raw value. See BOLTM-5068
   *
   * @param rating: Rating
   * @returns The human readable name of the rating system
   */
  getRatingSystemName(rating: Rating): string | number {
    if (_isNumber(rating?.ratingSystemId)) {
      return rating.ratingSystemId;
    }
    return rating?.ratingSystem?.name || rating?.ratingSystemId || '';
  }
}
