import { RatingSystemInterface } from '../rating-system/rating-system.model';
import { RatingInterface } from './rating.interface';
import { AuthoritativeRatingTypeEnum } from './authoritative-rating-type.enum';

export class Rating implements RatingInterface {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public ratingSystemId: number,
    public ratingSystem?: RatingSystemInterface,
    public authoritativeRatingType?: AuthoritativeRatingTypeEnum
  ) { }

  /**
   * Returns true if the rating is authoritative
   *
   * @return boolean
   */
  isAuthoritative(): boolean {
    return this.isTheatrical() || this.isHomeEdition();
  }

  /**
   * Returns true if the rating type of the instance is theatrical
   *
   * @return boolean
   */
  isTheatrical(): boolean {
    return this.authoritativeRatingType === AuthoritativeRatingTypeEnum.theatrical;
  }

  /**
   * Returns true if the rating type of the instance is home edition
   *
   * @return boolean
   */
  isHomeEdition(): boolean {
    return this.authoritativeRatingType === AuthoritativeRatingTypeEnum.homeEdition;
  }

  toString() {
    return this.name;
  }
}
