import { HttpError } from '@bolt/ui-shared/common';
import { includes as _includes } from 'lodash';

import { ConfigurationTypeEnum } from './type.enum';


export class Configuration {
  protected _description: string;
  protected _id: number;
  protected _name: string;
  protected _type: ConfigurationTypeEnum;
  protected _value: any;

  constructor(data: any) {
    this.initialize(data);
  }

  get description(): string {
    return this._description;
  }

  set description(aString: string) {
    this._description = aString;
  }

  get id(): number {
    return this._id;
  }

  set id(aNumber: number) {
    this._id = aNumber;
  }

  get name(): string {
    return this._name;
  }

  set name(aString: string) {
    this._name = aString;
  }

  get type(): ConfigurationTypeEnum {
    return this._type;
  }

  get value(): any {
    return this.mapValue();
  }

  set value(aValue: any) {
    this._value = aValue;
  }

  /**
   * Indicates if the current configuration value is a boolean.
   *
   * @returns boolean
   */
  isBoolean(): boolean {
    return this.type === ConfigurationTypeEnum.boolean;
  }

  /**
   * Indicates if the current configuration value is a number.
   *
   * @returns boolean
   */
  isNumber(): boolean {
    return this.type === ConfigurationTypeEnum.number;
  }

  /**
   * Indicates if the current configuration value is a string.
   *
   * @returns boolean
   */
  isString(): boolean {
    return this.type === ConfigurationTypeEnum.string;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @throws HttpError
   * @returns void
   */
  protected initialize(data: any): void {
    this._description = data.description ? data.description : '';
    this._id = data.id;
    this._name = data.name;
    this._value = data.value;

    if (_includes(ConfigurationTypeEnum, data.type)) {
      this._type = data.type;
    } else {
      throw new HttpError('Invalid type given for creating a configuration');
    }
  }

  /**
   * Maps the current value as the current type.
   *
   * @returns any
   */
  protected mapValue(): any {
    switch (this._type) {
      case ConfigurationTypeEnum.number:
        return parseInt(this._value, 10);
      case ConfigurationTypeEnum.string:
        return String(this._value);
      case ConfigurationTypeEnum.boolean:
        if (this._value.toLowerCase() === 'true') {
          return true;
        } else if (this._value.toLowerCase() === 'false') {
          return false;
        } else {
          throw new HttpError('Invalid boolean value for retriving the configuration');
        }
      default:
        throw new HttpError('Invalid type for retriving the configuration value');
    }
  }
}
