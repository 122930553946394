import { Injectable } from '@angular/core';
import { HttpError, SeverityEnum } from '@bolt/ui-shared/common';
import { MessageService } from 'primeng';
import { isNumber as _isNumber, isObject as _isObject } from 'lodash';

import { Notification } from '../models/notification/notification.model';


@Injectable()
export class NotificationService {
  protected defaultLife: number;

  constructor(
    protected messageService: MessageService
  ) {
    // this.defaultLife = this.appConfig.get('ux.growl.life');
    // TODO restore this when adding appConfig service in shared library.
    this.defaultLife = 8000;
  }

  /**
   * Cleans it.
   *
   * @param container string
   * @returns void
   */
  clean(container?: string): void {
    this.messageService.clear(container);
  }

  /**
   * Adds the given message and error.
   *
   * @param message string
   * @param error HttpError
   * @param container string
   * @param life number
   * @returns void
   */
  handleError(message: string, error?: HttpError, container?: string, life: number = 0): void {
    let detail: string;
    let reason: string;

    if (_isObject(error)) {
      detail = error.message;
      reason = error.toString();
    }

    const notification: Notification = new Notification(SeverityEnum.ERROR, message, detail, container, this.ensureLife(life));

    this.messageService.add(notification);
    console.error(`Message: ${message}`, `Reason: ${reason}`);
  }

  /**
   * Adds the given message.
   *
   * @param message string
   * @param details string
   * @param container string
   * @param life number
   * @returns void
   */
  handleNotice(message: string, details?: string, container?: string, life: number = 0): void {
    const notification: Notification = new Notification(SeverityEnum.INFO, message, details, container, this.ensureLife(life));
    this.messageService.add(notification);
  }

  /**
   * Adds the given message.
   *
   * @param message string
   * @param details string
   * @param container string
   * @param life number
   * @returns void
   */
  handleSuccess(message: string, details?: string, container?: string, life: number = 0): void {
    const notification: Notification = new Notification(SeverityEnum.SUCCESS, message, details, container, this.ensureLife(life));
    this.messageService.add(notification);
  }

  /**
   * Adds the given message.
   *
   * @param message string
   * @param details string
   * @param container string
   * @param life number
   * @returns void
   */
  handleWarning(message: string, details?: string, container?: string, life: number = 0): void {
    const notification: Notification = new Notification(SeverityEnum.WARN, message, details, container, this.ensureLife(life));
    this.messageService.add(notification);
  }

  /**
   * Ensures a life using the default value if `life` is `null`, `undefined` or a number lower than 1.
   *
   * @param life number
   * @throws HttpError
   * @returns number
   */
  protected ensureLife(life: number): number {
    let output: number;

    if (_isNumber(life)) {
      output = (life > 0) ? life : this.defaultLife;
    } else {
      throw new HttpError(`Invalid life given for a notification: ${life}`);
    }

    return output;
  }
}
